.module-bw-cpt-07_collapse {

    .collapse_top h4 {
        margin-bottom: 0px;
        line-height: 1em;
    }

    .btn {
        margin-top: 0;
        margin-bottom: 30px;
    }
    .txt, h4 {
        margin-bottom: 30px;
    }
    .table_container {
        overflow-x: scroll;
        overflow-y: hidden;
    }
    table {
        width: 100%;
        background: rgba(52, 52, 54, 0.04);
        border-radius: 12px;
        overflow: hidden;
        min-width: 700px;
        margin-top: 30px;
        margin-bottom: 30px;
        @include break(tablet){
            min-width: 600px
        }

        .space {
            display: none;
        }

        thead, tr {
            display: flex;
            width: 100%;
        }

        th,td {
            width: 100%;
            text-align: left;
            padding: 16px;
            flex-grow: 1;
            flex-shrink: 1;
            border-right: 1px solid $white;
            border-bottom: 1px solid $white;
            &:last-of-type {
                border-right: none;
            }
        }
    }

    .vendor-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 30px;

        .box {
            width: 370px;
            height: 130px;
            padding: 22px 32px;
            border-radius: 12px;
            background-color: $light-green;
            color: $green;
            text-decoration: none;
            margin-bottom: 30px;
            position: relative;
            transition: all 0.4s;
            @include break(mobile) {
               width: 100%;
            }

            .sub {
                font-size: 14px;
                padding-bottom: 10px;
            }

            h6 {
                margin-bottom: 0;
            }

            svg {
                position: absolute;
                top: 22px;
                right: 28px;

                path {
                    transition: all 0.4s;
                }
            }
        }

        a.box:hover {
            background-color: $green;
            color: $light-green;

            svg rect {
                fill: $light-green;
            }
        }
    }

}