.module-09-action_box {
    
    .container {
        background-size: cover;
        background-position: center;
        border-radius: 60px;
        padding: 58px 80px;
        color: $white;
        position: relative;
        overflow: hidden;
        @include break(mobile){
            padding: 30px;
            border-radius: 30px;
        }
    }
    
    &.layout-big .container {
        padding: 105px 250px;
        @include break(mobile){
            padding: 52px 40px;
        }
    }

    .content {
        position: relative;
        z-index: 2;
    }

    .btn_wrap {
        position: relative;
        z-index: 2;

        .btn {
            margin-left: 10px;
            margin-right: 10px;

            &:first-of-type {
                margin-left: 0px;
            }
            &:last-of-type {
                margin-right: 0px;
            }

            @include break(mobile){
                margin-left: 0;
                margin-right: 0;
                margin-top: 20px;
            }
        }
    }

    .shape {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        @include break(tablet){
            display: none!important;
        }
    }

    .bg_shape1 {
        .shape {
            top: 0;
            left: auto;
            right: 0;
            bottom: 0;
            height: 100%;
            width: auto;
        }
    }

    &.align_left .bg_shape1 .content {
        padding-right: 40%;
        @include break(tablet){
            padding-right: 0%;
        }
    }
    &.align_right .bg_shape1 {
        .shape {
            transform: rotateY(180deg);
            right: auto;
            left: 0;
        }
        .content {
            padding-left: 40%;
        }
    }



    .container.bg_green {
        .shape path {
            opacity: 0.3;
            fill: $light-green;
        }
        .btn_primary {
            color: $green;  
            border: 2px solid $white;
            padding: 14px 62px 14px 26px;
            &::after, &::before {
                background-color: $green;
            }

            &:hover {
                background-color: $green;
                &::after, &::before {
                    background-color: $white;
                }
            }
        }
        .btn_secondary:hover {
            color: $green;
            &::after, &::before {
                background-color: $green;
            }
        }
    }

    .container.bg_yellow  {
        color: $dark;
        .shape path {
            opacity: 0.3;
            fill: $light-yellow;
        }
        .btn_primary {
            color: $yellow;  
            border: 2px solid $white;
            padding: 14px 62px 14px 26px;
            &::after, &::before {
                background-color: $yellow;
            }

            &:hover {
                background-color: $yellow;
                &::after, &::before {
                    background-color: $white;
                }
            }
        }
        .btn_secondary:hover {
            color: $yellow;
            &::after, &::before {
                background-color: $yellow;
            }
        }
    }

    .container.bg_blue {
        .shape path {
            opacity: 0.3;
            fill: $light-blue;
        }
        .btn_primary {
            color: $blue;  
            border: 2px solid $white;
            padding: 14px 62px 14px 26px;
            &::after, &::before {
                background-color: $blue;
            }

            &:hover {
                background-color: $blue;
                &::after, &::before {
                    background-color: $white;
                }
            }
        }
        .btn_secondary:hover {
            color: $blue;
            &::after, &::before {
                background-color: $blue;
            }
        }
    }

    .container.bg_orange {
        .shape path {
            opacity: 0.3;
            fill: $light-orange;
        }
        .btn_primary {
            color: $orange;  
            border: 2px solid $white;
            padding: 14px 62px 14px 26px;
            &::after, &::before {
                background-color: $orange;
            }

            &:hover {
                background-color: $orange;
                &::after, &::before {
                    background-color: $white;
                }
            }
        }
        .btn_secondary:hover {
            color: $orange;
            &::after, &::before {
                background-color: $orange;
            }
        }
    }


}