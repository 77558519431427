.module-24-contact_form {
    position: relative;
    border-radius: 60px;
    @include break(mobile){
        border-radius: 30px
    }

    .bg_shape {
        position: absolute;
        left: 2.5%;
        right: 2.5%;
        bottom: -5%;
        z-index: 0;

        &.shape_color-light-pink svg path {
            fill: $pink;
        }
        &.shape_color-light-blue svg path {
            fill: $blue;
        }
        &.shape_color-light-green svg path {
            fill: $green;
        }
        &.shape_color-light-yellow svg path {
            fill: $yellow;
        }
        &.shape_color-light-orange svg path {
            fill: $orange;
        }
    }

    .container {
        display: flex;
        position: relative;
        z-index: 0;
        justify-content: space-between;
        @include break(mobile){
            flex-direction: column;
        }

        .content-left {
            min-width: 360px;
            width: 30%;
            flex-shrink: 0;
            padding-right: 20px;
            @include break(mobile){
                width: 100%;
                margin-bottom: 40px;
            }
        }
        .content-form {
            width: 60%;
            flex-shrink: 1;
            @include break(mobile){
                width: 100%;
            }
        }
    }
}