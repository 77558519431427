.module-26-iframe {
   .iframe_wrap {
    position: relative;
    display: flex;
    justify-content: center;

    iframe, >div {
        max-width: 100%;
    }

    p {
        width: 100%;
    }
   }
}