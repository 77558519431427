.module-bw-cpt-12_faq {

    .collapse_wrap {
        border: none!important;

        .collapse_top {
            padding-top: 24px;
            padding-bottom: 24px;
        }
    }

}