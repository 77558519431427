.module-22-contact_cards {

    .card_contact-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include break(mobile) {
            flex-direction: column;
            align-items: center;
        }

        .card_contact {
            border-radius: 20px;
            border: 1px solid rgba($dark, 0.4);
            padding: 26px;
            display: flex;
            width: 48%;
            max-width: 525px;

            @include break(mobile) {
                flex-direction: column;
                align-items: center;
                width: 100%;
                margin-bottom: 20px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            .content_img {
                flex-shrink: 0;
                width: 100px;
                margin-right: 35px;

                @include break(mobile) {
                    margin-right: 0;
                }

                .img {
                    width: 100%;
                    padding-bottom: 100%;
                    position: relative;
                    border-radius: 50%;

                    picture {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;

                        .image {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }

            .content_text {
                width: 100%;
                flex-shrink: 1;

                h5 {
                    padding-top: 20px;
                    margin-bottom: 7px;
                }

                h6 {
                    font-size: 1.1em;
                    margin-bottom: 20px;
                }

                .phone,
                .email {
                    display: flex;
                    align-items: center;
                    font-size: 14px;

                    span {
                        margin-left: 12px;
                    }
                }
            }
        }
    }

}