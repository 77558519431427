.module-03-editorial_text  {
    &.ratio-large .container {
        padding-right: 20%;
        @include break(tablet){
            padding-right: 0%;
         }
    }
    &.ratio-half .container {
        padding-right: 35%;
        @include break(tablet){
            padding-right: 0%;
         }
    }
}