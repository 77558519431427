header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;

  .nav_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 99;
    padding: 14px 5%;
    background-color: $white;

    @include break(small-screen) {
      padding: 12px 2.5%;
    }

    @include break(mobile) {
      padding: 5px 5%;
    }

    &.bw_nav {
      padding: 17px 5%;

      @include break(mobile) {
        padding: 12px 5%;
      }
    }

    .left {
      flex-shrink: 0;

      .logo {
        width: 180px;
        height: auto;
        margin-left: -12px;

        @include break(small-screen) {
          width: 135px;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      flex-shrink: 1;
      width: 100%;
      justify-content: flex-end;

      .nav {
        display: flex;
        align-items: center;
        margin-right: 5%;

        .top-title {
          display: flex;
          align-items: center;
          position: relative;
          flex-shrink: 0;
          font-size: 15px;
          @include font-medium;
          margin-left: 18px;
          transition: all 0.4s;
          cursor: pointer;

          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 1px;
            display: block;
            background-color: $pink;
            transition: width .25s ease-out;
          }

          @include break(small-screen) {
            font-size: 14px;
            margin-left: 16px;
          }

          &:first-of-type {
            margin-left: 0;
          }

          svg {
            margin-left: 5px;
            transition: all 0.4s;

            path {
              transition: all 0.4s;
            }
          }

          &:hover,
          &.active {
            color: $pink;

            &::before {
              width: calc(100% - 17px);
            }

            path {
              fill: $pink;
            }
          }

          &.active {
            svg {
              transform: rotate(-180deg);
            }
          }
        }

        @include break(nav-mobile) {
          display: none;
        }
      }

      .btn {
        margin-left: 15px;
        margin-top: 0;
        padding: 11px 20px;
        font-size: 14px;
        flex-shrink: 0;

        &::before,
        &::after {
          display: none;
        }

        @include break(small-screen) {
          padding: 5px 18px;
          font-size: 14px;
          margin-left: 18px;
        }
      }

      .btn_menu {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 7px 40px 7px 20px;
        font-size: 14px;
        @include font-medium;
        border-radius: 40px;
        border: 1px solid $dark;
        text-decoration: none;
        flex-shrink: 0;
        position: relative;
        transition: all 0.4s;

        &::after {
          content: " ";
          background-color: $dark;
          height: 2px;
          width: 9px;
          border-radius: 0.5px;
          right: 18px;
          position: absolute;
          top: 42%;
          transform: translate(-50%, -50%) rotate(50deg);
          transition: all 0.4s;
        }

        &::before {
          content: " ";
          background-color: $dark;
          height: 2px;
          width: 9px;
          border-radius: 0.5px;
          right: 18px;
          position: absolute;
          top: 55%;
          transform: translate(-50%, -50%) rotate(-50deg);
          transition: all 0.4s;
        }

        @include break(small-screen) {
          padding: 5px 25px 5px 18px;
          font-size: 14px;

          &::after,
          &::before {
            right: 8px;
          }

          &::before {
            top: 56%;
          }
        }

        @include break(nav-mobile) {
          display: none;
        }

        &:hover {
          background-color: $pink;
          border-color: $pink;
          color: $white;

          &::after,
          &::before {
            background-color: $white;
          }
        }
      }

      .burger {
        height: 21px;
        width: 21px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        div {
          background-color: $dark;
          width: 100%;
          height: 2px;
          margin-top: 1px;
          margin-bottom: 1px;
          transition: all 0.4s;
        }
      }

      .active .burger {
        div:nth-of-type(1) {
          transform: rotate(-45deg) translate(-3px, 3px);
        }

        div:nth-of-type(2) {
          width: 0;
        }

        div:nth-of-type(3) {
          transform: rotate(45deg) translate(-2.5px, -3px);
        }
      }

      .nav_mobile-btn {
        margin-left: 26px;
        display: none;
        position: relative;
        cursor: pointer;

        @include break(nav-mobile) {
          display: block;
        }

        .burger {
          height: 23px;
          width: 23px;
        }

        .close {
          opacity: 0;
          position: absolute;
          top: -10px;
          left: 7px;
          font-size: 35px;
          line-height: 41px;
        }
      }

      .search_btn {
        display: flex;
        align-items: center;
        margin-left: 15px;
        cursor: pointer;
        position: relative;

        .icon_search {
          transition: all 0.3s;
        }

        .icon_close {
          position: absolute;
          opacity: 0;
          top: 42%;
          left: 42%;
          transform: translate(-50%, -50%);
          transition: all 0.3s;
        }

        &.active {
          .icon_search {
            opacity: 0;
          }

          .icon_close {
            opacity: 1;
          }
        }
      }
    }

    &.nav_landing {
      .left {
        width: 100%;

        a {
          display: flex;
          justify-content: center;
          
          @include break(tablet) {
            justify-content: flex-start;
          }
        }
      }

      .right {
        position: absolute;
        right: 5%;
      }
    }
  }

  .filter {
    position: fixed;
    opacity: 0.4;
    pointer-events: all;
    z-index: 0;
    display: none;
  }


  // NAV DESKTOP
  .nav_desktop {
    background-color: $white;
    position: absolute;
    z-index: 999;
    top: calc(100% - 1px);
    left: 0;
    right: 0;
    border-top: 1px solid #E9EDF2;
    border-bottom: 3px solid $pink;
    display: none;
    overflow: hidden;
    height: 0;


    .menu {
      margin: 60px 5% 84px 5%;
      position: relative;
      height: 60vh;
      margin: 40px 5% 84px 5%;

      @include break(small-screen) {
        height: 63vh;
        margin: 40px 5% 60px 5%;
      }
    }

    .menu_tab {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow-y: scroll;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 0 !important
      }

      ;
      overflow: -moz-scrollbars-none;
      -ms-overflow-style: none;
      // display: flex;
      display: none;
      justify-content: space-between;
      width: 100%;
    }

    .list_wrap {
      display: flex;
      justify-content: space-between;
      margin-left: -20px;

      .col {
        width: 30%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding-left: 20px;

        a {
          text-decoration: none;
          margin-bottom: 25px;
          @include font-bold;
          text-transform: uppercase;

          @include break(small-screen) {
            margin-bottom: 20px;
          }

          &:last-of-type {
            margin-bottom: 0;
          }

          &.lvl2 {
            font-size: 1.1em;

            @include break(small-screen) {
              font-size: 1.1em;
            }
          }

          &.lvl3 {
            font-size: 16px;

            @include break(small-screen) {
              font-size: 14px;
            }
          }
        }
      }
    }

    .col_title {
      width: 370px;
      padding-right: 80px;
      border-right: 1px solid #E9EDF2;
      margin-right: 80px;
      flex-shrink: 0;
      margin-right: 5%;

      @include break(small-screen) {
        width: 300px;
        padding-right: 20px;
        margin-right: 2.5%;
      }

      .title-h3 {
        color: $pink;
        margin-bottom: 0;
      }

      .txt {
        font-size: 16px;
        margin-top: 8px;
      }

      .btn {
        margin-top: 32px;
      }
    }

    .col_content {
      flex-shrink: 2;
      width: 100%;

      >div {
        padding-top: 40px;

        &:first-of-type {
          padding-top: 0;
        }
      }

      .img-link_title,
      .buttons-list_title {
        font-size: 20px;
        @include font-bold;
        margin-bottom: 20px;
        text-transform: uppercase;
      }

      .img-link_wrap {
        display: flex;
        flex-wrap: wrap;
        margin-right: -25px;
        margin-bottom: -25px;

        @include break(small-height) {
          margin-bottom: -15px;
        }

        a {
          text-decoration: none;
          width: 245px;
          margin-right: 25px;
          margin-bottom: 25px;

          @include break(small-height) {
            width: 205px;
            margin-bottom: 15px;
          }

          @media screen and (max-width: 1450px) {
            width: 205px;
            height: 155px;
          }

          .img {
            width: 245px;
            height: 245px;
            border-radius: 7px;
            background-position: center;
            background-size: cover;

            @include break(small-height) {
              width: 205px;
              height: 155px;
            }

            @media screen and (max-width: 1450px) {
              width: 205px;
              height: 155px;
            }
          }

          .title {
            font-size: 15px;
            @include font-bold;
            margin-top: 12px;
            text-transform: uppercase;
          }
        }
      }

      .buttons-list_wrap {
        display: flex;
        flex-wrap: wrap;
        margin-right: -12px;

        a {
          text-decoration: none;
          margin-right: 12px;
          margin-bottom: 12px;
          color: $pink;
          @include font-bold;
          font-size: 16px;
          letter-spacing: 0.96px;
          text-transform: uppercase;
          padding: 12px 16px;
          line-height: 1em;
          border-radius: 46px;
          background-color: rgba($pink, 0.12);

          &:hover {
            color: $white;
            background-color: rgba($pink, 1);
          }
        }
      }

      &.items-full {
        display: flex;

        >div {
          width: 50%;
          padding-top: 0;

          &:first-of-type {
            width: 82%;
            padding-top: 0;
          }
        }

        .img-link_wrap a {
          @media screen and (max-width: 1450px) {
            margin-right: 15px;
          }
        }
      }
    }

    .col_highlight {
      flex-shrink: 1;
      margin-left: 5%;
      width: 350px;

      @include break(small-screen) {
        width: 335px;
        margin-left: 2.5%;
      }

      .card_highlight {
        overflow: hidden;
        display: flex;
        width: 350px;
        height: 385px;
        background-position: center;
        background-size: cover;
        border-radius: 7px;
        text-decoration: none;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 20px 36px;
        position: relative;

        @include break(small-screen) {
          width: 300px;
          height: 340px;
          padding: 20px 26px;
        }

        .card-filter {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0.5;
          background: linear-gradient(180deg, rgba(52, 52, 54, 0.00) 0%, #343436 100%);
          z-index: 1;
        }

        .title-h4 {
          position: relative;
          z-index: 2;
        }

        svg {
          margin-left: auto;
          position: absolute;
          top: 20px;
          right: 20px;
          z-index: 2;

          circle,
          path {
            transition: all 0.4s;
          }

        }

        .card-overlay {
          position: absolute;
          width: 48px;
          height: 48px;
          background-color: $pink;
          border-radius: 50%;
          top: 20px;
          right: 20px;
          transition: all 0.4s;
          z-index: 1;
        }

        &:hover {
          color: $white;

          circle {
            fill: $light-pink;
          }

          path {
            fill: $pink;
          }

          .card-overlay {
            transform: scale(20);
          }
        }
      }
    }

    .bw_title a {
      text-decoration: none;
      display: flex;
      align-items: center;
      color: $pink;

      svg {
        margin-left: 20px;
      }
    }

    .bottom {
      background-color: $white;
      bottom: 0;
      left: 0;
      right: 0;
      padding-top: 30px;
      padding-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;

      @include break(small-screen) {
        padding-top: 20px;
        padding-bottom: 20px;
      }

      .btn_close {
        @include font-medium;
        border: 1px solid $pink;
        padding: 4px 8px;
        letter-spacing: 0.6px;
        text-transform: uppercase;
        line-height: 1em;
        font-size: 10px;
        color: $pink;
        display: flex;
        align-items: center;
        border-radius: 34px;
        cursor: pointer;
        transition: all 0.4s;

        svg {
          margin-right: 5px;

          path {
            transition: all 0.4s;
          }
        }

        &:hover {
          color: $white;
          background-color: rgba($pink, 1);

          path {
            fill: $white;
          }
        }
      }
    }

    .menu_tab-search {
      .seach_container {
        width: 100%;
        max-width: 970px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;

        h4 {
          margin-bottom: 0;
          padding-bottom: 24px;
        }

        .search-bar {
          margin-top: 24px;
          margin-bottom: 50px;
          width: 100%;
        }

        form>div {
          display: flex;
          align-items: baseline;
          width: 100%;
          position: relative;

          input[type=text] {
            width: 100%;
            border: 1px solid $pink;
            padding-left: 32px;
            padding-right: 220px;
            border-radius: 36px;
            height: 55px;
            background-color: $white;

            @include break(mobile) {
              padding-left: 20px;
              padding-right: 20px;
              margin-right: 0;
            }
          }

          #searchsubmit {
            height: 55px;
            width: auto;
            position: absolute;
            top: 11px;
            margin: 0;
            right: 0;
            text-align: left;
            padding-right: 60px;

            @include break(mobile) {
              top: 5px;
              color: $pink;
              padding: 0;
              width: 58px;
            }
          }

          .relevanssi-live-search-results-showing {
            top: 0 !important;
            left: 20px !important;
            right: 20px !important;
            border-color: $pink !important;
            width: auto !important;
            margin-top: -5px;

            a {
              text-decoration: none;
              color: $pink;

              &:hover {
                color: $dark-pink;
              }
            }
          }

          .relevanssi-live-search-result-status {
            display: none !important;
          }
        }

        .form_wrap {
          position: relative;

          .icon {
            position: absolute;
            right: 20px;
            top: 54%;
            transform: translateY(-50%);
            z-index: 3;
            pointer-events: none;

            @include break(mobile) {
              top: 50%;
              right: 16px;
            }
          }
        }

        h6 {
          padding-top: 55px;
          padding-bottom: 15px;
        }

        .tag_wrap {
          display: flex;
          flex-wrap: wrap;

          .tag {
            margin-right: 10px;
            padding: 9px 14px;
            border-radius: 46px;
            background: rgba(154, 10, 128, 0.12);
            font-size: 12px;
            letter-spacing: 0.72px;
            text-transform: uppercase;
            @include font-bold;
            text-decoration: none;
            color: $pink;
            transition: all 0.3s;
            margin-bottom: 12px;

            &:hover {
              background: $pink;
              color: $white;
            }
          }
        }
      }
    }
  }


  // NAV MOBILE
  .nav_mobile {
    background-color: $white;
    position: fixed;
    position: absolute;
    z-index: 999;
    top: calc(100% - 1px);
    left: 0;
    right: 0;
    height: 100vh;
    border-top: 1px solid #E9EDF2;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      width: 0 !important
    }

    ;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    display: none;

    .main_menu {
      padding: 40px 5%;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-y: scroll;
      overflow-x: hidden;
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        width: 0 !important
      }

      ;
      overflow: -moz-scrollbars-none;
      -ms-overflow-style: none;
      height: calc(100vh - 80px);
      padding-bottom: 20vh;

      @include break(mobile) {
        height: calc(100vh - 65px);
      }

      .top-title {
        padding-top: 16px;
        padding-bottom: 16px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-transform: uppercase;
        font-size: 1.6em;
        @include font-bold;
        border-bottom: 1px solid #E9EDF2;
        text-decoration: none;
        transition: all 0.4s;
        cursor: pointer;

        svg {
          margin-left: 8px;
          transition: all 0.4s;

          path {
            transition: all 0.4s;
          }
        }

        &:hover,
        &.active {
          color: $pink;

          path {
            fill: $pink;
          }
        }
      }

      .btn {
        margin-top: 60px;
        padding: 11px 40px;
        font-size: 18px;
        flex-shrink: 0;
        justify-content: center;

        &::before,
        &::after {
          display: none;
        }

        @include break(mobile) {
          width: 100%;
          max-width: 500px;
        }
      }
    }


    .menu_tab {
      scroll-behavior: smooth;
      // display: flex;
      flex-direction: column;
      background-color: $white;
      height: calc(100vh - 80px);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: none;

      @include break(mobile) {
        height: calc(100vh - 70px);
      }

      .top {
        flex-shrink: 0;
      }

      .back {
        background-color: $pink;
        display: flex;
        padding: 16px 5%;
        align-items: center;
        color: $white;
        font-size: 16px;
        @include font-medium;

        svg {
          margin-right: 8px;
        }
      }

      .title-h3 {
        display: block;
        padding: 42px 5% 32px 5%;
        color: $pink;
        margin-bottom: 0;
        text-decoration: none;
        display: flex;
        align-items: center;

        svg {
          margin-left: 12px;
        }
      }

      .content {
        flex-shrink: 1;
        height: 100%;
        padding: 0;
        padding: 0 5% 25vh 5%;
        overflow-y: scroll;
        overflow-x: hidden;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
          width: 0 !important
        }

        ;
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;

        >div {
          padding-top: 40px;

          &:first-of-type {
            padding-top: 0;
          }
        }

        .img-link_title,
        .buttons-list_title {
          font-size: 20px;
          @include font-bold;
          margin-bottom: 20px;
          text-transform: uppercase;
        }

        .img-link_wrap {
          display: flex;
          width: 101vw;
          margin-left: -6%;
          overflow-x: scroll;
          overflow-y: visible;
          scroll-behavior: smooth;

          &::-webkit-scrollbar {
            width: 0 !important
          }

          ;
          overflow: -moz-scrollbars-none;
          -ms-overflow-style: none;
          scroll-snap-type: x mandatory;

          a {
            text-decoration: none;
            width: 160px;
            margin-right: 8px;
            scroll-snap-align: start;
            scroll-margin: 5vw;

            .img {
              width: 160px;
              height: 160px;
              border-radius: 7px;
              background-position: center;
              background-size: cover;
            }

            .title {
              font-size: 16px;
              @include font-bold;
              margin-top: 12px;
              text-transform: uppercase;
            }

            &:first-of-type {
              margin-left: 5vh;
            }

            &:last-of-type {
              margin-right: 5vh;
            }
          }
        }

        .buttons-list_wrap {
          display: flex;
          flex-wrap: wrap;
          margin-right: -6px;

          a {
            text-decoration: none;
            margin-right: 6px;
            margin-bottom: 6px;
            color: $pink;
            @include font-bold;
            font-size: 16px;
            letter-spacing: 0.96px;
            text-transform: uppercase;
            padding: 12px 16px;
            line-height: 1em;
            border-radius: 46px;
            background-color: rgba($pink, 0.12);

            &:hover {
              color: $white;
              background-color: rgba($pink, 1);
            }
          }
        }

        .list_wrap {
          a {
            display: block;
            text-decoration: none;
            @include font-bold;
            text-transform: uppercase;

            &:last-of-type {
              margin-bottom: 0;
            }

            &.lvl2 {
              font-size: 18px;
              margin-top: 16px;
              margin-bottom: 16px;
            }

            &.lvl3 {
              font-size: 16px;
              padding-top: 6px;
              padding-bottom: 20px;
            }
          }
        }

        .col_highlight {
          width: 100%;
          display: flex;

          @include break(tablet) {
            justify-content: center;
          }

          .card_highlight {
            display: flex;
            width: 335px;
            height: 371px;
            background-position: center;
            background-size: cover;
            border-radius: 7px;
            text-decoration: none;
            color: $white;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 20px 26px;

            svg {
              margin-right: -16px;
              margin-left: auto;

              circle,
              path {
                transition: all 0.4s;
              }

            }

            &:hover {
              color: $pink;

              circle {
                fill: $pink;
              }

              path {
                fill: $white;
              }
            }
          }
        }
      }



    }

  }


}