.module-15-timeline {
    
    &.layout-tab {
        .timeline-tab_name-container{
            overflow-x: scroll;
            overflow-y: visible;
            &::-webkit-scrollbar { width: 0 !important };
            overflow: -moz-scrollbars-none;
            -ms-overflow-style: none;
            
            .tab-name_container-inner {
                display: flex;
                position: relative;
                padding-bottom: 18px;
                margin-bottom: 30px;
                border-bottom: 2px solid rgba(52, 52, 54, 0.20);
                width: max-content;
                min-width: 100%;
            }
    
            .tab-name {
                font-size: 16px;
                @include font-bold;
                line-height: 140%;
                letter-spacing: 0.96px;
                text-transform: uppercase;
                margin-right: 32px;
                color: rgba($dark, 0.6);
                cursor: pointer;
                transition: all 0.4s;
                position: relative;
                z-index: 2;
    
                &::after {
                    z-index: 2;
                    content: " ";
                    bottom: -20px;
                    background-color: $pink;
                    height: 2px;
                    width: 100%;
                    display: block;
                    position: absolute;
                    opacity: 0;
                    transition: all 0.4s;
                }
    
                &:hover {
                    color: $pink;
                }
    
                &.active {
                    color: $pink;
                    pointer-events: none;
                    &::after { 
                        opacity: 1;
                    }
                }
            }
        }
    
        .timeline-tab_container {
            display: flex;
            @include break(tablet){
                align-items: flex-start;
             }

            svg {
                flex-shrink: 0;
                cursor: pointer;
                margin-top: 135px;
                transform: translateY(50%);
                ellipse, path {
                    transition: all 0.4s;
                }

                &:hover {
                    ellipse{
                        fill-opacity: 1;
                    } 
                    path {
                        fill: $white;
                    } 
                }
                @include break(tablet){
                   display: none;
                }
            }
            .arrow_left {
                margin-right: 50px;
            }
            .arrow_right {
                margin-left: 50px;
            }

            .timeline-tab_slider {
                width: 100%;
                flex-shrink: 1;
                min-height: 370px;
                position: relative;
                @include break(tablet){
                    min-height: 600px;
                 }
            }

            .timeline-tab_slide {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                @include break(tablet){
                    flex-direction: column;
                    top: 0%;
                    transform: translateY(0%);
                    align-items: center;
                 }


                .timeline-tab_image {
                    width: 370px;
                    height: 370px;
                    flex-shrink: 0;
                    margin-right: 8%;
                    position: relative;
                    @include break(tablet){
                        margin-bottom: 30px;
                        margin-right: 0;
                    }
                    @include break(mobile){
                        width: 250px;
                        height: 250px;
                     }

                     picture {
                         position: absolute;
                         left: 0;
                         top: 0;
                         width: 100%;
                         height: 100%;
                     }
     
                     .img {
                         width: 100%;
                         height: 100%;
                         object-fit: cover;
                     }
                }

                &:first-of-type {
                    display: flex;
                }
                
                .btn_sec_pink {
                    margin-left: 20px;
                    @include break(mobile){
                        margin-left: 0px;
                        margin-top: 20px;
                    }
                }
            }
        }
    }



    &.layout-card {
        .container_timeline {
            display: flex;
            padding-top: 24px;
            padding-bottom: 100px;
            overflow-y: hidden;
            overflow-x: scroll;
            scroll-behavior: smooth;
            padding-left: 25%;
            padding-right: 25%;
            scroll-snap-type: x mandatory;

            &::-webkit-scrollbar {
                height: 4px;
                width: 100%;
                -webkit-appearance: none;
                -webkit-overflow-scrolling: auto;
            }
            &::-webkit-scrollbar-button:start:increment {
                width: calc((100vw - 1172px) / 2);
                @include break(small-screen){
                    width: 5%;
                }
            }
            &::-webkit-scrollbar-button:end:increment {
                width: calc((100vw - 1172px) / 2);
                @include break(small-screen){
                    width: 5%;
                }
            }
            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: rgba($dark,0.2);
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 20px;
                background-color: $dark;
            }
        }
        .timeline_card {
            position: relative;
            z-index: 0;
            flex-shrink: 0;
            width: 373px;
            height: 466px;
            border-radius: 20px;
            background-position: center;
            background-size: cover;
            transform: rotate(4deg);
            padding: 32px;
            display: flex;
            overflow: hidden;
            flex-direction: column;
            scroll-snap-align: start;
            scroll-margin:30vw;
            @include break(mobile){
                height: 260px;
                width: 200px;
                padding: 24px 18px;
                border-radius: 10px;
            }

            picture {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }

            .img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            
            &:nth-child(2n) {
                transform: rotate(-8deg);
                z-index: 4;
            }
            
            &-date {
                font-size: 4.77em;
                @include font-bold;
                margin-bottom: 10px;
                line-height: 108%;
                position: relative;
                z-index: 2;
                flex-shrink: 0;
                @include break(mobile){
                    font-size: 2.25em;
                }
            }

            &-txt {
                position: relative;
                z-index: 2;
                color: $white;
                overflow-y: scroll;
                overflow-x: hidden;
                scroll-behavior: smooth;
                height: 100%; 
                flex-shrink: 1;
                opacity: 0;
                transform: translateY(50px);
                transition: all 0.4s;
                @include break(mobile){
                    opacity: 1;
                    font-size: 14px;
                    transform: translateY(0);
                }
            }

            &-overlay {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                opacity: 0;
                transition: all 0.4s;
                transform: translateY(100%);
                @include break(mobile){
                    opacity: 1;
                    transform: translateY(0);
                }
            }

            &:nth-child(5n + 1) {
                .timeline_card-date {
                    color: $light-green;
                }
                .timeline_card-overlay {
                    background-color: $green;
                }
            }
            &:nth-child(5n + 2) {
                .timeline_card-date {
                    color: $light-pink;
                }
                .timeline_card-overlay {
                    background-color: $pink;
                }
            }
            &:nth-child(5n + 3) {
                .timeline_card-date {
                    color: $light-blue;
                }
                .timeline_card-overlay {
                    background-color: $blue;
                }
            }
            &:nth-child(5n + 4) {
                .timeline_card-date {
                    color: $light-orange;
                }
                .timeline_card-overlay {
                    background-color: $orange;
                }
            }
            &:nth-child(5n + 5) {
                .timeline_card-date {
                    color: $light-yellow;
                }
                .timeline_card-overlay {
                    background-color: $yellow;
                }
            }


            &:hover {
                .timeline_card-overlay {
                    opacity: 1;
                    transform: translateY(0);
                }
                .timeline_card-txt {
                    transform: translateY(0);
                    opacity: 1;
                }
            }
        }
    }

}