.module-bw-cpt-13_redirection {

    .tab_nav-container {
        width: 100%;
        overflow-x: scroll;
        overflow-y: visible;
        &::-webkit-scrollbar { width: 0 !important };
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;

        .tab_nav {
            display: flex;
            position: relative;
            padding-bottom: 12px;
            margin-bottom: 30px;
            border-bottom: 2px solid rgba(52, 52, 54, 0.20);
            width: max-content;
            min-width: 100%;

            .tab-name {
                font-size: 16px;
                @include font-bold;
                line-height: 140%;
                letter-spacing: 0.96px;
                text-transform: uppercase;
                margin-right: 32px;
                color: rgba($dark, 0.6);
                cursor: pointer;
                transition: all 0.4s;
                position: relative;
                z-index: 2;
                flex-shrink: 0;
    
                &::after {
                    z-index: 2;
                    content: " ";
                    bottom: -14px;
                    background-color: $pink;
                    height: 2px;
                    width: 100%;
                    display: block;
                    position: absolute;
                    opacity: 0;
                    transition: all 0.4s;
                }
    
                &:hover {
                    color: $pink;
                }
    
                &.active {
                    color: $pink;
                    pointer-events: none;
                    &::after { 
                        opacity: 1;
                    }
                }
            }
        }
    }

    .tab_container {
        position: relative;
        width: 100%;
        height: 760px;

        .tab {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            display: none;

            &:first-of-type {
                display: block;
            }
        }
    }

    .row_redirection {
        display: flex;
        width: 100%;
        align-items: center;
        text-decoration: none;
        margin-bottom: 20px;
        @include break(mobile) {
            flex-direction: column;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        .img {
            width: 370px;
            height: 250px;
            margin-right: 10%;
            flex-shrink: 0;
            position: relative;
            @include break(mobile) {
                width: 100%;
                height: auto;
                padding-bottom: 60%;
                margin-right: 0;
                margin-bottom: 10px;
            }

            picture {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            .image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .redirection_content {
            flex-shrink: 1;
            width: 100%;

            p {
                font-size: 14px;
                @include font-bold;
                margin-bottom: 10px;
            }
        }
    }

}