.module-32-hero-landing {
	position: relative;
	overflow: hidden;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	margin-top: 0;
	margin-bottom: 0;

	@include break(tablet) {
	}

	&.bg_pink .breadcrumb ul li a:hover {
		color: $light-pink;
	}

	&:not(.bg-media) {
		&:not(.bg-white) {
			.btn {
				background-color: $white;
				color: $pink;

				&::before,
				&::after {
					background-color: $pink;
				}
			}
		}
	}

	.bg {
		picture {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			
			@include break(tablet) {
				transform: translateX(-50%);
			}
		}

		.img {
			height: 100%;
			width: 100%;
			object-fit: cover;

			&.mobile {
				display: block;

				@include break(mobile) {
					display: none;
				}

				&-display {
					display: none;

					@include break(mobile) {
						display: block;
					}
				}
			}
		}

		picture,
		video,
		iframe {
			width: 100%;
			height: 100%;
			object-fit: cover;

			@include break(tablet) {
				left: 50%;
			}

			img {
				object-fit: contain;

				@include break(tablet) {
					object-fit: cover;
				}

				&.mobile {
					@include break(tablet) {
						display: none;
					}

					&-display {
						@include break(tablet) {
							display: block;
						}
					}
				}
			}
		}
	}

	.wrapper-content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 60px 0;

		@include break(tablet) {
			flex-direction: column;
			gap: 40px;
		}

		@include break(mobile) {
			padding-top: 40px;
			padding-bottom: 40px;
		}

		.col-left {
			width: 65%;
			padding: 60px;
			margin-left: -60px;
			color: $white;

			@include break(tablet) {
				padding: 32px 22px;
				margin-left: 0;
				width: 100%;
			}

			&.bg-white {
				color: $dark;
				background-color: $white;
				border-radius: 20px;
			}

			.title-h1,
			.title-h2 {
				margin-bottom: 35px;
			}

			.txt {
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%;
			}
		}

		.col-right {
			width: 35%;

			@include break(tablet) {
				width: 100%;
			}

			picture,
			.cover {
				display: flex;
				width: 450px;
				height: 450px;

				@include break(tablet) {
					width: 100%;
					height: 350px;
				}

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;

					@include break(tablet) {
						display: none;
					}

					&.mobile {
						display: none;

						@include break(tablet) {
							display: block;
						}
					}
				}
			}

			.cover {
				position: relative;
				object-fit: cover;

				.play {
					width: 81px;
					height: 81px;
				}

				&.mobile {
					@include break(tablet) {
						display: none;
					}

					&-display {
						display: none;
						@include break(tablet) {
							display: block;
						}
					}
				}
			}
		}

		&.order-mobile {
			.col-left {
				@include break(tablet) {
					order: 2;
				}
			}
			.col-right {
				@include break(tablet) {
					order: 1;
				}
			}
		}
	}
}
