.module-21-stories {
    position: relative;

    .bg_shape {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        svg {
            height: 100%;
            width: 100%;
            @include absolute-center(center);
        }

        &.shape_color-light-pink svg path {
            fill: $light-pink;
        }

        &.shape_color-light-green svg path {
            fill: $light-green;
        }

        &.shape_color-light-yellow svg path {
            fill: $light-yellow;
        }

        &.shape_color-light-blue svg path {
            fill: $light-blue;
        }

        &.shape_color-light-orange svg path {
            fill: $light-orange;
        }

        @include break(mobile) {
            display: none;
        }
    }

    .card-row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: -40px;

        @include break(small-screen) {
            justify-content: space-evenly;
        }

        @include break(mobile) {
            flex-direction: column;
            align-items: center;
            margin-bottom: -25px;
        }
    }

    .cards_highlight {
        display: flex;
        justify-content: space-between;

        @include break(mobile) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .highlight {
            width: 100%;
            flex-shrink: 1;
            padding-right: 25px;

            @include break(mobile) {
                padding-right: 0;
            }

            a {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding: 40px;
                position: relative;
                width: 100%;
                height: 100%;
                background-position: center;
                background-size: cover;
                text-decoration: none;
                color: $white;
                min-height: 350px;

                @include break(mobile) {
                    padding: 25px;
                    width: 100%;
                    max-width: 370px;
                    margin-bottom: 25px;
                    height: 119vw;
                    max-height: 500px;
                    margin-left: auto;
                    margin-right: auto;
                }

                picture {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }

                .img,
                .image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .card_filter {
                    z-index: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: linear-gradient(180deg, rgba(52, 52, 54, 0.00) 33.5%, rgba(52, 52, 54, 1) 100%);
                }

                .cat {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: $light-green;
                    color: green;
                    font-size: 12px;
                    @include font-medium;
                    line-height: 100%;
                    letter-spacing: 0.6px;
                    text-transform: uppercase;
                    padding: 8px 12px;
                    z-index: 1;
                }

                .title-h6 {
                    position: relative;
                    z-index: 1;
                }

                .txt_date {
                    margin-bottom: 16px;
                }

                .cta {
                    position: relative;
                    z-index: 1;
                    font-size: 16px;
                    line-height: 140%;
                    letter-spacing: 0.96px;
                    text-transform: uppercase;
                    @include font-bold;

                    svg {
                        width: 20px;
                        margin-bottom: -4px;
                        margin-left: 14px;

                        path {
                            transition: all 0.4s;
                        }
                    }
                }

                .txt_wrap {
                    overflow: hidden;

                    .txt_inner {
                        transform: translateY(1.2em);
                        transition: all 0.4s;

                        @include break(mobile) {
                            transform: translateY(0);
                        }
                    }
                }

                &:hover {
                    color: $white;

                    .txt_inner {
                        transform: translateY(0);
                    }
                }
            }
        }

        .card-post_highlight-small {
            text-decoration: none;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            flex-shrink: 0;

            &:last-of-type {
                margin-bottom: 0;
            }

            @include break(mobile) {
                margin-right: 0px;
                flex-direction: column;
                width: 100%;
                max-width: 370px;
                margin-bottom: 25px;
                align-items: flex-start;
            }

            picture {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;

                .image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .img {
                width: 210px;
                position: relative;
                height: 140px;
                flex-shrink: 0;
                margin-right: 25px;

                @include break(mobile) {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 15px;
                    padding-bottom: 67%;
                }

                .cat {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: $light-green;
                    color: green;
                    font-size: 10px;
                    @include font-medium;
                    line-height: 100%;
                    letter-spacing: 0.6px;
                    text-transform: uppercase;
                    padding: 6px 10px;
                }
            }

            .content {
                max-width: 240px;

            }
        }

        .cards_col {
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include break(mobile) {
                width: 100%;
                align-items: center;
            }

            .txt_date {
                color: $pink;
                @include font-medium;
            }
        }


    }

    .card-post {
        width: 370px;
        text-decoration: none;
        margin-right: 10px;
        margin-bottom: 40px;

        &:last-of-type {
            margin-right: 0;
        }

        @include break(mobile) {
            width: 100%;
            max-width: 370px;
            margin-right: 0px;
            margin-bottom: 25px;
        }

        picture {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            .image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .img {
            width: 100%;
            padding-bottom: 67%;
            background-position: center;
            background-size: cover;
            position: relative;
            margin-bottom: 20px;

            @include break(mobile) {
                margin-bottom: 15px;
            }

            .cat {
                position: absolute;
                top: 0;
                left: 0;
                background-color: $light-green;
                color: green;
                font-size: 10px;
                @include font-medium;
                line-height: 100%;
                letter-spacing: 0.6px;
                text-transform: uppercase;
                padding: 6px 10px;
            }
        }

        .txt_date {
            color: $pink;
            text-transform: uppercase;
            font-size: 14px;
            @include font-bold;
            margin-bottom: 5px;
        }

        &_ghost {
            height: 0;
            margin: 0;
            padding: 0;

            @include break(small-screen-only) {
                display: none;
            }

            @include break(mobile) {
                display: none;
            }
        }
    }

    .container_btn {
        text-align: center;
    }

}