.module-bw-cpt-06_table {

   
    .table_container {
        overflow-x: scroll;
        overflow-y: hidden;

    }
    table {
        width: 100%;
        background: rgba(52, 52, 54, 0.04);
        border-radius: 12px;
        overflow: hidden;
        min-width: 700px;

        .space {
            display: none;
        }

        thead, tr {
            display: flex;
            width: 100%;
        }

        th,td {
            width: 100%;
            text-align: left;
            padding: 16px;
            flex-grow: 1;
            flex-shrink: 1;
            border-right: 1px solid $white;
            border-bottom: 1px solid $white;
            &:last-of-type {
                border-right: none;
            }
        }

        
    }

}