.template-stories-archive, .template-news-archive {
    .last_post {
        a.card-post_highlight {
            display: flex;
            justify-content: space-between;
            text-decoration: none;
            @include break(mobile){
               flex-direction: column;
            }

            .card_img {
                position: relative;
                width: 57.5%;
                padding-bottom: 40%;
                flex-grow: 0;
                flex-shrink: 0;
                @include break(mobile){
                    width: 100%;
                    padding-bottom: 86%;
                }
                picture {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
    
                    .image {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .cat {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: $light-green;
                    color: green;
                    font-size: 10px;
                    @include font-medium;
                    line-height: 100%;
                    letter-spacing: 0.6px;
                    text-transform: uppercase;
                    padding: 6px 10px;
                }
            }

            .content {
                flex-grow: 0;
                flex-shrink: 0;
                width: 37.5%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                @include break(mobile){
                    width: 100%;
                    margin-top: 15px;
                }
                .date {
                    font-size: 14px;
                    color: rgba($dark, 0.6);
                    margin-top: 5px;
                    margin-bottom: 5px;
                    transition: all 0.6s;
                    @include break(mobile){
                      margin-top: 12px;
                    }
                  }
                .cta {
                    font-size: 16px;
                    line-height: 140%;
                    letter-spacing: 0.96px;
                    text-transform: uppercase;
                    @include font-bold;
                    color: $pink;
                    margin-top: 10px;
                    svg {
                        width: 20px;
                        margin-bottom: -4px;
                        margin-left: 14px;
                        path {
                            transition: all 0.4s;
                        }
                    }
                }
            }

            &:hover .date {
                color: $pink;
            }
        }
    }

    .filter_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 80px;
        @include break(mobile){
            align-items: flex-start;
            flex-direction: column;
            justify-content: flex-start;
            padding-bottom: 40px;
         }
        .left {
            display: flex;
            align-items: center;
            color: $pink;
            @include break(mobile){
                width: 100%;
             }
            
            h6 {
                margin: 0;
                @include break(mobile){
                  display: none;
                }
            }
            .drop_cat {
                position: relative;
                @include break(mobile){
                    width: 100%;
                 }
                select {
                    padding: 16px 42px 16px 32px;
                    border: 1px solid $pink;
                    color: $pink;
                    border-radius: 34px;
                    margin-left: 60px;
                    letter-spacing: 0.96px;
                    text-transform: uppercase;
                    font-size: 16px;
                    @include font-medium;
                    appearance: none;
                    background-color: transparent;
                    @include break(mobile){
                        margin-left: 0px;
                        width: 100%;
                     }
                }

                svg {
                    position: absolute;
                    top: 50%;
                    right: 14px;
                    transform: translateY(-50%);
                    pointer-events: none
                }
            }
        }
        .right {
            @include font-bold;
            @include break(mobile){
                display: none;
             }
        }
    }

    .card-row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include break(mobile){
            flex-direction: column;
            align-items: center;
        }

        .card-post {
            width: 370px;
            text-decoration: none;
            margin-right: 10px; 
            margin-bottom: 48px;
            &:last-of-type {
                margin-right: 0;
            }
            @include break(mobile){
                width: 100%;
                max-width: 370px;
                margin-right: 0px; 
                margin-bottom: 25px;
            }
    
            .img {
                width: 100%;
                padding-bottom: 67%;
                position: relative;
                margin-bottom: 20px;
                @include break(mobile){
                    margin-bottom: 15px;
                }

                picture {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
    
                    .image {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
    
                .cat {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: $light-green;
                    color: green;
                    font-size: 10px;
                    @include font-medium;
                    line-height: 100%;
                    letter-spacing: 0.6px;
                    text-transform: uppercase;
                    padding: 6px 10px;
                }
            }
            h6 {
                margin-bottom: 6px;
            } 
            .date {
                font-size: 14px;
                color: rgba($dark, 0.6);
                margin-top: 20px;
                @include break(mobile){
                  margin-top: 12px;
                }
              }
        }
    }

    .nav-links {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-right: 75px;
        padding-left: 75px;
        a, span {
            text-decoration: none;
            @include font-bold;
            transition: all 0.4s;
            padding: 16px;

            &:hover {
                color: $pink;
            }
        }
        .current {
            line-height: 1;
            color: $pink;
            background-color: #F6EDF5;
            border-radius: 50%;
            width: 42px;
            height: 42px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
        }
        a.next.page-numbers {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        a.prev.page-numbers {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

}