.module-33-2cols-landing {
	position: relative;
	overflow: hidden;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	margin-top: 0;
	color: $dark;
	background-color: $light-grey;

	@include break(tablet) {
	}

	.wrapper-content {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@include break(tablet) {
			flex-direction: column;
			gap: 40px;
		}

		@include break(mobile) {
			padding-top: 40px;
			padding-bottom: 40px;
		}

		.col-left {
			width: 50%;

			@include break(tablet) {
				width: 100%;
			}

			.title-h2 {
				margin-bottom: 20px;
			}

			.txt {
				font-size: 17px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%;
			}

			picture,
			video {
				width: 100%;
				margin-bottom: 40px;
			}

			.mobile {
				display: block;

				@include break(tablet) {
					display: none;
				}

				&-display {
					display: none;

					@include break(tablet) {
						display: block;
						margin-bottom: 40px;
					}
				}
			}
		}

		.col-right {
			width: 50%;
			display: flex;
			justify-content: flex-end;

			@include break(tablet) {
				width: 100%;
			}

			picture,
			.cover {
				display: flex;
				width: 450px;
				height: 450px;

				@include break(tablet) {
					width: 100%;
					height: 350px;
				}

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;

					&.mobile {
						display: block;

						@include break(tablet) {
							display: none;
						}

						&-display {
							display: none;

							@include break(tablet) {
								display: block;
							}
						}
					}
				}
			}

			.cover {
				position: relative;
				object-fit: cover;

				.play {
					width: 81px;
					height: 81px;
				}

				&.mobile {
					@include break(tablet) {
						display: none;
					}

					&-display {
						display: none;
						@include break(tablet) {
							display: block;
						}
					}
				}
			}
		}

		&.order-mobile {
			.col-left {
				@include break(tablet) {
					order: 2;
				}
			}
			.col-right {
				@include break(tablet) {
					order: 1;
				}
			}
		}
	}
}

.module-32-hero-landing + .module-33-2cols-landing {
    margin-top: 120px;
}