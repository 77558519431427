.module-34-boxes-landing {
	padding-top: 0 !important;
	padding-bottom: 0!important;

	.top_container {
		.title-h2 {
			text-align: center;
			margin: 0 auto;
			max-width: 60%;

			@include break(tablet) {
				max-width: 100%;
			}
		}
	}
	.card {
		width: calc((100% / 2) - 37px);
		padding: 32px 28px;
		border-radius: 20px;
		position: relative;
		overflow: hidden;
		transition: none;

		&:nth-child(1),
		&:nth-child(2),
		&:nth-child(3) {
			width: calc((100% / 3) - 37px);
		}

		@include break(tablet) {
			width: 100%;

			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3) {
				width: 100%;
			}
		}

		.icon {
			max-width: 100px;
		}

		.card-content {
			.title-h2 {
				font-size: 20px;
				line-height: 150%;
				margin-bottom: 12px;
			}

			&_txt {
				font-size: 17px;
				font-weight: 400;
				line-height: 150%;
			}
		}

		&.layout-vertical {
			.icon {
				margin-bottom: 25px;
			}
		}
		&.layout-horizontal {
			display: flex;
			gap: 22px;

			.icon {
				min-width: 60px;
			}
		}
	}

	&.layout-grid {
		.grid_container {
			display: flex;
			flex-wrap: wrap;
			column-gap: 37px;
			row-gap: 50px;

			@include break(tablet) {
				row-gap: 30px;
			}
		}
	}
}
