.module-bw-cpt-09_logo-box {

    .logo_box {
        border-radius: 40px;
        padding: 52px 48px;
        display: flex;
        @include break(tablet){
           flex-direction: column;
           align-items: center;
           padding: 26px 24px;
        }

        .left {
            width: 175px;
            margin-right: 50px;
            flex-shrink: 0;
            
            img {
                width: 100%;
                height: auto;
            }
        }
        .right {
            width: 100%;
            flex-shrink: 1;
        }
    }

}