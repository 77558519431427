.bw-flex-modules {

    .header_projects {
        margin-bottom: 100px;
        @include break(mobile){
            margin-bottom: 60px;
        }

        .img {
            width: 100%;
            height: 58vh;
            max-height: 600px;
            background-position: center;
            background-size: cover;
            position: relative;
        }
    }


    .info_banner {

        .container {
            display: flex;
            align-items: center;
            padding-top: 60px;
            padding-bottom: 60px;
            position: relative;
            @include break(tablet){
                flex-direction: column;
                align-items: center;
             }

            .col_left {
                width: 100%;
                flex-shrink: 1;
                position: relative;
                z-index: 2;
            }
            .col_right {
                width: 360px;
                flex-shrink: 0;
                display: flex;
                justify-content: flex-end;
                position: relative;
                z-index: 2;
                margin-left: 5%;
                @include break(tablet){
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    margin-top: 20px;
                    margin-left: 0%;
                 }

                .countdown {
                    display: flex;
                    
                    .time {
                        margin-left: 50px;
                        text-align: center;
                        @include break(tablet){
                            margin-left: 15px;
                            margin-right: 15px;
                         }
                        .number {
                            font-size: 4.5em;
                            @include font-bold;
                            line-height: 1em;
                        }
                    }
                }
            }

            .bg_shape {
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                z-index: 1;
            }
        }
        p {
            font-size: 1.33em;
        }

    }

    .cpt-page-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 120px;
        padding-top: 45px;
        @include break(tablet){
            padding-bottom: 60px;
            flex-direction: column;
        }

        .sidebar {
            position: relative;
            width: 405px;
            @include break(tablet){
                width: 100%;
                margin-top: 0px;
            }

            .sidebar_inner {
                border-radius: 40px 0px 0px 40px;
                background: rgba(126, 182, 226, 0.20);
                padding: 65px 35px 65px 60px;
                height: calc(100vh - 96px);
                position: sticky;
                top: 96px;
                left: 0;
                overflow-y: scroll;
                @include break(small-screen){
                    top: 80px;
                    height: calc(100vh - 80px);
                }
                @include break(tablet){
                    height: auto;
                    border-radius: 40px 40px 0px 0px;
                    padding: 50px 5%;
                }

                .side_info {
                    margin-bottom: 52px;
                }

                .side_info-top {
                    display: flex;
                    align-items: flex-end;
                    padding-bottom: 15px;

                    h6 {
                        font-size: 1.3em;
                        margin-right: 16px;
                        margin-bottom: 0;
                    }

                    .copy_link {
                        display: flex;
                        font-size: 14px;
                        color: $pink;
                        text-transform: uppercase;
                        align-items: center;
                        line-height: 1em;
                        @include font-bold;
                        cursor: pointer;
                    }
                }

                .btn {
                    margin-top: 20px;
                }

                .side_map{
                    margin-bottom: 52px;
                    .map {
                        width: 315px;
                        height: 230px;
                        position: relative;
                        border-radius: 12px;
                        border: 3px solid $white;
                        position: relative;
                        overflow: hidden;
                
                        iframe {
                            @include absolute-center(center);
                        }
                    }
                    .txt {
                        padding-top: 14px;
                    }
                    .link {
                        font-size: 16px;
                        @include font-bold;
                        text-decoration: none;
                        color: $pink;
                        display: flex;
                        align-items: center;
                        text-transform: uppercase;
                        margin-top: 15px;
                        svg {
                            margin-left: 14px;
                        }
                    }
                }
            }
        }

        >.content {
            padding-left: calc((100vw - 1172px) / 2);
            padding-right: calc((100vw - 1172px) / 2);
            width: 100%;
            flex-shrink: 1;
            @include break(small-screen){
                padding-left: 5%;
                padding-right: 5%;
            }
            @include break(tablet){
                width: 100%;
            }

            section:first-of-type {
                margin-top: 80px;
                @include break(tablet){
                    margin-top:30px;
                }
            }
        }

    }
}