.module-bw-cpt-10_logo-list {

    .title-h6 {
        font-size: 16px;
        text-transform: uppercase;
        color: $pink;
        @include font-bold;
    }
    
    .logo_wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-left: -2.5%;
        margin-right: -2.5%;
        align-items: center;

        img {
            width: 20%;
            height: auto;
            margin-left: 2.5%;
            margin-right: 2.5%;
            @include break(mobile){
                width: 45%;
            }
        }
    }

}