
// POPUP GLOBAL

.popup_global {
    width: 100%;
    padding: 0 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $orange;
    color: $white;
    line-height: 100%;
    font-size: 14px;
    position: relative;
    z-index: 99;
    overflow: hidden;
    display: none;

    .popup_icon {
        flex-shrink: 0;
    }

    .txt {
        display: flex;
        align-items: center;
        padding: 14px 20px;
        @include break(mobile){
            flex-direction: column;
            align-items: flex-start;
        }

        a {
            text-decoration: none;
            @include font-bold;
            transition: all 0.3s;
            margin-left: 22px;
            display: inline-flex;
            align-items: center;
            text-transform: uppercase;
            letter-spacing: 0.84px;
            text-transform: uppercase;
            font-size: 12px;
            @include break(mobile){
                margin-top: 5px;
                margin-left: 0;
            }

            svg {
                margin-left: 8px;
                path {
                    transition: all 0.3s;
                }
            }

            &:hover {
                color: $light-orange;
                path {
                    fill: $light-orange;
                }
            }
        }
    }

    .close {
        flex-shrink: 0;
        cursor: pointer;
        svg {
            path {
                transition: all 0.3s;
            }
        }
        &:hover {
            path {
                fill: $light-orange;
            }
        }
    }

    &.pop-color_blue {
        background-color: $blue;

        .txt a:hover {
            color: $light-blue;
            path {
                fill: $light-blue;
            }
        }
        .close:hover path {
            fill: $light-blue;
        }
    }

    &.pop-color_yellow {
        background-color: $yellow;
        color: $dark;
        svg path {
            fill: $dark;
        }

        .txt a:hover {
            color: $light-yellow;
            path {
                fill: $light-yellow;
            }
        }
        .close:hover path {
            fill: $light-yellow;
        }
    }
    
    &.pop-color_magenta {
        background-color: $pink;

        .txt a:hover {
            color: $light-pink;
            path {
                fill: $light-pink;
            }
        }
        .close:hover path {
            fill: $light-pink;
        }
    }

    &.pop-color_grey {
        background-color: $dark;

        .txt a:hover {
            color: $light-pink;
            path {
                fill: $light-pink;
            }
        }
        .close:hover path {
            fill: $light-pink;
        }
    }
}


.popup_post {
    border-radius: 20px;
    width: 400px;
    display: none;
    justify-content: space-between;
    background-color: $light-orange;
    line-height: 100%;
    font-size: 16px;
    padding: 12px;
    position: fixed;
    right: 2.5%;
    bottom: 30px;
    z-index: 9999;
    @include break(mobile){
        width: 95%;
        left: 2.5%;
    }

    .popup_icon {
        background-color: $orange;
        width: 54px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        flex-shrink: 0;
    }

    .txt {
        padding-left: 18px;
        padding-right: 18px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        flex-shrink: 1;

        p {
            line-height: 120%;
        }

        a {
            text-decoration: none;
            @include font-bold;
            transition: all 0.3s;
            margin-top: 5px;
            display: inline-flex;
            align-items: center;
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 0.84px;
            text-transform: uppercase;

            svg {
                margin-left: 8px;
                path {
                    transition: all 0.3s;
                }
            }

            &:hover {
                color: $orange;
                path {
                    fill: $orange;
                }
            }
        }
    }
    .close {
        flex-shrink: 0;
        padding-right: 12px;
        padding-top: 4px;
        cursor: pointer;
        svg {
            path {
                transition: all 0.3s;
            }
        }
        &:hover {
            path {
                fill: $orange;
            }
        }
    }

    &.pop-color_blue {
        background-color: $light-blue;
        .popup_icon {
            background-color: $blue;
        }
        .txt a:hover {
            color: $blue;
            path {
                fill: $blue;
            }
        }
        .close:hover path {
            fill: $blue;
        }
    }

    &.pop-color_yellow {
        background-color: $light-yellow;
        color: $dark;
        svg path {
            fill: $dark;
        }

        .popup_icon {
            background-color: $yellow;
        }
        .txt a:hover {
            color: $yellow;
            path {
                fill: $yellow
            }
        }
        .close:hover path {
            fill: $yellow;
        }
    }
    
    &.pop-color_magenta {
        background-color: $light-pink;
        .popup_icon {
            background-color: $pink;
        }
        .txt a:hover {
            color: $pink;
            path {
                fill: $pink;
            }
        }
        .close:hover path {
            fill: $pink;
        }
    }

    &.pop-color_grey {
        background-color: $border-grey;
        .popup_icon {
            background-color: $dark;
        }
        .txt a:hover {
            color: $dark;
            path {
                fill: $dark;
            }
        }
        .close:hover path {
            fill: $dark;
        }
    }
}



.popup_page {

    .popup_page-container {
        border-radius: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $light-orange;
        line-height: 100%;
        font-size: 17px;
        padding: 16px 25px;

        .popup_icon {
            background-color: $orange;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            flex-shrink: 0;
        }

        .txt {
            padding-left: 18px;
            padding-right: 18px;
            display: flex;
            align-items: center;
            @include break(mobile){
                flex-direction: column;
                align-items: flex-start;
            }
    
            p {
                line-height: 120%;
            }
    
            a {
                margin-left: 18px;
                text-decoration: none;
                @include font-bold;
                transition: all 0.3s;
                margin-top: 3px;
                display: inline-flex;
                align-items: center;
                font-size: 14px;
                text-transform: uppercase;
                letter-spacing: 0.84px;
                text-transform: uppercase;
                @include break(mobile){
                    margin-left: 0;
                }
    
                svg {
                    margin-left: 8px;
                    path {
                        transition: all 0.3s;
                    }
                }
    
                &:hover {
                    color: $orange;
                    path {
                        fill: $orange;
                    }
                }
            }
        }

    }


    &.pop-color_blue .popup_page-container {
        background-color: $light-blue;
        .popup_icon {
            background-color: $blue;
        }
        .txt a:hover {
            color: $blue;
            path {
                fill: $blue;
            }
        }
    }

    &.pop-color_yellow  .popup_page-container {
        background-color: $light-yellow;
        color: $dark;
        svg path {
            fill: $dark;
        }

        .popup_icon {
            background-color: $yellow;
        }
        .txt a:hover {
            color: $yellow;
            path {
                fill: $yellow
            }
        }
    }
    
    &.pop-color_magenta .popup_page-container {
        background-color: $light-pink;
        .popup_icon {
            background-color: $pink;
        }
        .txt a:hover {
            color: $pink;
            path {
                fill: $pink;
            }
        }
    }

    &.pop-color_grey  .popup_page-container {
        background-color: $border-grey;
        .popup_icon {
            background-color: $dark;
        }
        .txt a:hover {
            color: $dark;
            path {
                fill: $dark;
            }
        }
    }
}

// DEFAUL TEMPLATE (MODULE) || ARCHIVE NEWS
.page-template-default .popup_page, .template-archive .popup_page, .bw_page-template-default .popup_page {
    margin-top: -95px;
    @include break(mobile){
        margin-top: -30px;
    }
}

// TEMPLATE HOME || TEMPLATE CONF ACHIVE 
.template-home .popup_page, .template-conf-archive .popup_page {
    margin-top: -95px;
    margin-bottom: 120px;
    @include break(mobile){
        margin-top: -30px;
        margin-bottom: 60px;
    }
}

// TEMPLATE CONF PAST || PROJECTS || NEWS || TENANTS 
.template-conf .popup_page, .template-projects .popup_page, .template-news .popup_page, .template-tenants .popup_page {
    margin-top: -75px;
    margin-bottom: 100px;
    @include break(mobile){
        margin-top: -20px;
        margin-bottom: 60px;
    }
}

// STORIES
.template-story .popup_page {
    margin-top: 0;
    margin-bottom: 60px;
}

// TEMPLATES ACTIVITIES || EVENTS
.template-activities .popup_page, .template-events .popup_page {
    margin-top: -20px;
    margin-bottom: 120px;
    @include break(mobile){
        margin-bottom: 60px;
    }
}
