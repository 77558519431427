.sup-view.template-home {

    .header_home {
        height: 75vh;
        position: relative;
        @include break(tablet){
            height: 70vh;
        }

        .content {
            position: relative;
            z-index: 2;
            padding-top: 13vh;
            color: $white;
            width: 65%;
            @include break(small-screen){
                width: 80%;
            }
            @include break(mobile){
                width: 100%;
            }
        }
    }

    .sub-text .intro-text {
        padding: 30px 20%;
        text-align: center;
        color: $white;
        margin-bottom: 120px;
        @include break(mobile){
            padding: 10px 5%;
            margin-bottom: 60px;
        }
    }

}
