.module-29-annual-numbers {
    &:not(.bg_white) {
        padding-top: 0;
        padding-bottom: 0;
    }

    .card-project {
        width: 100%;
        height: 375px;
        border-radius: 12px;
        position: relative;
        overflow: hidden;
        color: $white;
        transition: none;

        @include break(tablet) {
            width: 100%;
        }

        &.item-10 {
            grid-area: auto / span 3;

            &:hover {
                .card-project_overlay-bg {
                    transform: scale(40);
                }
            }
        }

        .card-filter {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0.5;
            background: linear-gradient(180deg, rgba(52, 52, 54, 0.00) 0%, #343436 100%);
            z-index: 1;
        }

        &_top {
            background-position: center;
            background-size: auto;
            background-repeat: no-repeat;
            width: 100%;
            position: relative;
            height: 100%;
        }

        .arrow {
            position: absolute;
            top: 32px;
            right: 32px;
            z-index: 4;

            svg {
                width: 62px;
                height: 62px;
                padding: 12px;
                background-color: $light-pink;
                border-radius: 50%;
                transition: background-color 0.4s;

                @include break(tablet) {
                    background-color: transparent;
                }

                path {
                    transition: fill 0.4s;
                }
            }

            @include break(mobile) {
                top: 36px;
                right: 36px;

                svg {
                    width: 52px;
                    height: 52px;
                }
            }
        }

        &_overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            padding: 40px 34px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            transition: background-color 0.4s;
            overflow-y: auto;
            overflow-x: hidden;
            z-index: 2;

            .title-h2 {
                margin-bottom: 0;
                color: $white;
                position: relative;
                z-index: 2;
            }
        }

        .card-project_overlay-bg {
            position: absolute;
            width: 62px;
            height: 62px;
            background-color: $light-pink;
            border-radius: 50%;
            top: 32px;
            right: 32px;
            transition: all 0.4s;
            z-index: 1;
        }

        &_txt {
            display: none;
            overflow: hidden;
            z-index: 2;
            position: relative;
        }

        .txt_date {
            margin-top: 10px;
        }

        &_txt-inner {
            padding-top: 10px;
        }

        &:hover {
            .card-project_overlay {
                color: $dark;

                .title-h2 {
                    color: $dark;
                }
            }

            .arrow {
                svg {
                    background-color: $pink;
                }

                path {
                    fill: $light-pink;
                }
            }

            .card-project_overlay-bg {
                transform: scale(30);
            }
        }

        &_ghost {
            padding: 0 !important;
            height: 0 !important;
        }

        &:hover {
            .card-project_overlay-bg {
                transform: scale(20);
            }
        }
    }

    &.layout-grid {
        .grid_container {
            display: grid;
            grid-auto-rows: 1fr;
            grid-template-columns: repeat(3, 1fr);
            gap: 25px;

            @include break(tablet) {
                grid-template-columns: repeat(2, 1fr);
            }

            @include break(mobile) {
                display: flex;
                flex-direction: column;
                gap: 25px 0;
            }
        }
    }

    .bg {
        &_green {
            .arrow {
                svg {
                    background-color: $light-green;

                    path {
                        fill: $green;
                    }
                }
            }

            &:hover {
                .arrow {
                    svg {
                        background-color: $light-green;

                        path {
                            fill: $green;
                        }
                    }
                }
            }

            .card-project_overlay-bg {
                background-color: $light-green;
            }
        }


        &_yellow {
            .arrow {
                svg {
                    background-color: $light-yellow;

                    path {
                        fill: $yellow;
                    }
                }
            }

            &:hover {
                .arrow {
                    svg {
                        background-color: $light-yellow;

                        path {
                            fill: $yellow;
                        }
                    }
                }
            }

            .card-project_overlay-bg {
                background-color: $light-yellow;
            }
        }

        &_blue {
            .arrow {
                svg {
                    background-color: $light-blue;

                    path {
                        fill: $blue;
                    }
                }
            }

            &:hover {
                .arrow {
                    svg {
                        background-color: $light-blue;

                        path {
                            fill: $blue;
                        }
                    }
                }
            }

            .card-project_overlay-bg {
                background-color: $light-blue;
            }
        }

        &_orange {
            .arrow {
                svg {
                    background-color: $light-orange;

                    path {
                        fill: $orange;
                    }
                }
            }

            &:hover {
                .arrow {
                    svg {
                        background-color: $light-orange;

                        path {
                            fill: $orange;
                        }
                    }
                }
            }

            .card-project_overlay-bg {
                background-color: $light-orange;
            }
        }
    }
}