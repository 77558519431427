footer {
    background-color: $dark;
    color: $white;
    padding: 80px 0 50px 0;
    .footer_container {
        margin-left: 5%;
        margin-right: 5%;
        display: flex;
        justify-content: space-between;
        @include break(tablet){
            flex-direction: column;
        }
    }

    .left {
        width: 555px;
        border-right: 1px solid $white;
        padding-right: 95px;
        flex-shrink: 0;
        @include break(tablet){
            width: 100%;
            padding-right: 0px;
            padding-bottom: 50px;
            margin-bottom: 50px;
            border-right: none;
            border-bottom: 1px solid $white;
        }
        h5 {
            font-size: 3.666em;
            margin-bottom: 0;
            @include break(tablet){
                font-size: 2.666em;
            }
        }
        h6 {
            margin-top: 60px;
            font-size: 1.333em;
            margin-bottom: 0;
            @include break(tablet){
                font-size: 1.25em;
            }
        }
        .btn {
            margin-top: 32px;
            @include break(tablet){
                margin-top: 20px;
            }
        }
        .socials {
            margin-top: 90px;
            @include break(tablet){
                display: flex;
                width: 100%;
                justify-content: space-between;
                margin-top: 48px;
                margin-right: -2%;
                margin-left: -2%;
            }
            .icon_social {
                transform: scale(0.8);
                @include break(tablet){
                    transform: scale(0.6);
                }
                svg {
                    path {
                        fill: $dark;
                    }
                    circle, ellipse {
                        fill: $white;
                    }

                    &:hover {
                        path {
                            fill: $white;
                        }
                        circle, ellipse {
                            fill: $pink;
                        } 
                    }
                }
            }
        }
    }
    .right {
        width: 100%;
        padding-left: 10%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include break(tablet){
            padding-left: 0;
        }

        .col_wrap {
            width: 100%;
            column-count: 2;
        }

        .main-list {
            width: 90%;
            page-break-inside: avoid;
            break-inside: avoid;
            margin-bottom: 48px;
            @include break(tablet){
                width: 95%;
            }

            .main-item a {
                text-decoration: none;
                font-size: 1.1em;
                @include font-bold;
                line-height: 150%;
                text-transform: uppercase;

                &:hover {
                    color: $light-pink;
                }
            }

            .sub-list {
                margin-top: 16px;
                a {
                    text-decoration: none;
                    font-size: 16px;
                    &:hover {
                        color: $light-pink;
                    }
                }
            }
        }

        .link_bw a {
            text-decoration: none;
            @include font-bold;
            padding-top: 40px;
            display: flex;
            align-items: center;

            svg {
                margin-left: 14px;
                width: 20px;
                path {
                    transition: all 0.4s;
                 }
            }
            &:hover {
                color: $light-pink;
                svg path {
                   fill: $light-pink;
                }
            }
        }
    }

    .copyright {
        justify-content: space-between;
        align-items: center;
        padding-top: 50px;
        border-top: 1px solid $white;
        margin-top: 40px;
        font-size: 16px;
        line-height: 150%;
        @include break(tablet){
            padding-top: 20px;
            font-size: 14px;
            flex-direction: column-reverse;
            .right_bottom {
                margin-bottom: 16px;
            }
        }

        .left_bottom {
            width: 100%;
            flex-shrink: 1;
            padding-right: 2.5%;
        }
        .right_bottom {
            width: 400px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @include break(tablet){
                flex-direction: column;
                justify-content: center;
            }
            svg {
                flex-shrink: 0;
            }
            .wpml-ls{
                flex-shrink: 0;
                margin-right: 50px;
                @include break(tablet){
                    margin-right: 0px;
                    margin-bottom: 15px;
                }

                li:nth-of-type(2) {
                    border-left: 2px solid $white;
                }

                a {
                    padding: 0px 10px 2px;
                }
            }
        }

        a {
            &:hover {
                color: $light-pink;
            }
        }
    }

}