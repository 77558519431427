.module-05-collapse_content {

    .top_container {
        text-align: center;
        padding-bottom: 60px;
    }
    
    .collapse_wrap {
        border-top: 1px solid $border-grey;

        &:last-of-type {
            border-bottom: 1px solid $border-grey;
        }

        .collapse_top {
            padding-top: 32px;
            padding-bottom: 32px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            @include break(mobile){
                padding-top: 20px;
                padding-bottom: 20px;
            }

            h5 {
                transition: all 0.4s;
                margin-bottom: 0;
            }

            .plus {
                margin-left: 60px;
                flex-shrink: 0;
                width: 23px;
                height: 23px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                .line1 {
                    background-color: $dark;
                    width: 100%;
                    height: 2px;
                    transition: all 0.4s;
                }
                .line2 {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    background-color: $dark;
                    width: 2px;
                    height: 100%;
                    transition: all 0.4s;
                }
            }

            &:hover {
                h4 {
                    color: $pink;
                }
                .line1, .line2 {
                    background-color: $pink;
                }
            }
        }

        .collapse_container {
            display: none;
            overflow: hidden;
            .collapse_inner {
                padding-bottom: 22px;
                .txt {
                    padding-bottom: 30px;
                    width: 100%;
                }
                .img {
                    padding-bottom: 30px;
                    width: 100%;
                }

                .two-col {
                    display: flex;
                    justify-content: space-between;
                    @include break(mobile){
                        flex-direction: column;
                    }

                    .col-txt {
                        width: 65%;
                        @include break(mobile){
                            width: 100%;
                        }
                    }
                    .col-img {
                        width: 25%;
                        @include break(mobile){
                            width: 100%;
                        }
                    }
                }

                .collapse_btn-container {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    .btn {
                        margin-right: 15px;
                        width: 435px;
                        margin-top: 0px;
                        margin-bottom: 30px;
                    }
                }
            }
        }

        &.open {
            .plus .line2 {
                transform: translate(-50%,-50%) rotate(90deg);
            }
        }
    }
}