.module-14-impact {
    
    .impact-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .card-impact {
            text-decoration: none;
            text-align: center;
            margin-bottom: 80px;
            @include break(mobile){
                margin-bottom: 40px;
            }
            .card-impact_top {
                width: 100%;
                padding-bottom: 100%;
                background-position: center;
                background-size: cover;
                position: relative;

                picture {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }

                .img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .card-impact_title {
                font-size: 1.1em;
                @include font-bold;
                line-height: 140%;
                text-transform: uppercase;
                margin-top: 30px;
            }

            &_ghost {
                margin: 0;
                padding: 0;
                height: 0;
            }
        }

    }


    &.layout-big {
        .impact-container {
            padding-left: 5%;
            padding-right: 5%;
            @include break(mobile){
                padding-left: 0%;
                padding-right: 0%;
            }
        } 
        .card-impact { 
            width: 46%;
            @include break(mobile){
                width: 48.5%;
            }
        }
    }
    &.layout-small {
        .card-impact { 
            width: 23.5%;
            @include break(mobile){
                width: 48.5%;
            }
        }
    }

    .container_btn {
        text-align: center;
    }
}