.module-01-header {
    position: relative;
    border-radius: 0px 0px 60px 60px;
    overflow: hidden;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: 0;

    @include break(mobile) {
        border-radius: 0px 0px 30px 30px;
    }


    &.bg_pink .breadcrumb ul li a:hover {
        color: $light-pink;
    }


    // HUB
    .top {
        min-height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 54px;
        position: relative;

        @include break(mobile) {
            min-height: 50vh;
        }

        picture {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }

        .img {
            height: 100%;
            width: 100%;
            object-fit: cover;

            @include break(mobile) {
                display: none;
            }

            &.mobile {
                display: none;

                @include break(mobile) {
                    display: block;
                }
            }
        }

        .title-h1 {
            position: relative;
            color: $white;
            z-index: 2;
            text-align: center;
            width: 80%;
        }
    }

    .hub-content {
        padding-top: 70px;
        padding-bottom: 70px;
        padding-left: 2%;
        padding-right: 2%;
        text-align: center;
        color: $white;

        @include break(mobile) {
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }


    // SHAPE

    &.layout-shape .container {
        height: 430px;
        padding-top: 104px;
        padding-bottom: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include break(mobile) {
            flex-direction: column;
            padding-top: 94px;
            padding-bottom: 40px;
            height: auto;
            min-height: 40vh;
        }

        .title_wrap {
            position: relative;
            z-index: 2;
            flex-shrink: 1;

            @include break(mobile) {}

            .title-h1 {
                color: $white;
            }
        }

        .shape_wrap {
            width: 375px;
            flex-shrink: 0;

            @include break(mobile) {
                position: absolute;
                bottom: -11vh;
                right: -38%;
                width: 100%;
                z-index: 0;

                svg {
                    width: 100%;
                    max-width: 235px;
                }
            }
        }
    }


}