.template-conf-archive {

    .intro-text_section {
        .container {
            padding-right: 35%;

            @include break(tablet) {
                padding-right: 0%;
            }
        }
    }

    .current_section {
        h2 {
            margin-bottom: 48px;

            @include break(mobile) {
                margin-bottom: 20px;
            }
        }

        a {
            text-decoration: none;
        }

        .img {
            position: relative;
            padding-bottom: 56%;
            margin-bottom: 24px;

            @include break(mobile) {
                margin-bottom: 14px;
            }

            picture {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;

                .image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .info {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            h4 {
                margin-bottom: 0;

                @include break(mobile) {
                    margin-bottom: 0;
                }
            }

            .date {
                flex-shrink: 0;
                margin-left: 20px;
                font-size: 14px;
                color: rgba($dark, 0.6);

                @include break(mobile) {
                    margin-top: 12px;
                }
            }
        }
    }

    .latest_section {
        h2 {
            margin-bottom: 48px;

            @include break(mobile) {
                margin-bottom: 20px;
            }
        }

        .conf_container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            @include break(mobile) {
                flex-direction: column;
                align-items: center;
            }

            .card_conf {
                width: 47.5%;
                flex-shrink: 0;
                margin-bottom: 30px;

                @include break(mobile) {
                    width: 100%;
                }

                a {
                    text-decoration: none;
                }

                .img {
                    position: relative;
                    padding-bottom: 56%;
                    margin-bottom: 24px;

                    @include break(mobile) {
                        margin-bottom: 14px;
                    }

                    picture {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;

                        .image {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .info {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;

                    h5 {
                        margin-bottom: 0;
                        line-height: 1em;

                        @include break(mobile) {
                            margin-bottom: 0;
                        }
                    }

                    .date {
                        flex-shrink: 0;
                        margin-left: 20px;
                        font-size: 14px;
                        color: rgba($dark, 0.6);

                        @include break(mobile) {
                            margin-top: 12px;
                        }
                    }
                }
            }
        }

    }

}