.module-17-content_cards {

    .card_container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include break(mobile){
            flex-direction: column;
        }

        .card-content {
            width: 31%;
            border-radius: 20px;
            padding: 28px;
            margin-bottom: 20px;
            @include break(mobile){
                width: 100%;
                border-radius: 16px;
                padding: 22px;
            }

            h5 {
                margin-bottom: 20px;
            }

            h6 {
                font-size: 1.1em;
                margin-bottom: 12px;
                line-height: 150%;
            }

            &_ghost {
                height: 0;
                padding: 0;
                margin: 0;
            }

            &.bg_light-pink {
                h5, strong {
                    color: $pink;
                }
            }
            &.bg_light-green {
                h5, strong {
                    color: $green;
                }
            }
            &.bg_light-yellow {
                h5, strong {
                    color: $yellow;
                }
            }
            &.bg_light-blue {
                h5, strong {
                    color: $blue;
                }
            }
            &.bg_light-orange {
                h5, strong {
                    color: $orange;
                }
            }
        }

        a.card-content {
            text-decoration: none;
            transition: all 0.4s;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            h5 {
                transition: all 0.4s;
            }
            h6 {
                font-size: 14px;
                font-weight: 400;
                line-height: 150%;
                margin-bottom: 7px;
            }

            svg {
                display: block;
                margin-left: auto;
                path {
                    transition: all 0.4s;
                }
            }

            &.bg_light-pink {
                h5, strong {
                    color: $dark;
                }
            }
            &.bg_light-green {
                h5, strong {
                    color: $dark;
                }
            }
            &.bg_light-yellow {
                h5, strong {
                    color: $dark;
                }
            }
            &.bg_light-blue {
                h5, strong {
                    color: $dark;
                }
            }
            &.bg_light-orange {
                h5, strong {
                    color: $dark;
                }
            }


            &.bg_light-pink:hover {
                color: $pink;
                h5, strong {
                    color: $pink;
                }
                svg path {
                    fill: $pink;
                }
            }
            &.bg_light-green:hover {
                color: $green;
                h5, strong {
                    color: $green;
                }
                svg path {
                    fill: $green;
                }
            }
            &.bg_light-yellow:hover {
                color: $yellow;
                h5, strong {
                    color: $yellow;
                }
                svg path {
                    fill: $yellow;
                }
            }
            &.bg_light-blue:hover {
                color: $blue;
                h5, strong {
                    color: $blue;
                }
                svg path {
                    fill: $blue;
                }
            }
            &.bg_light-orange:hover {
                color: $orange;
                h5, strong {
                    color: $orange;
                }
                svg path {
                    fill: $orange;
                }
            }
        }
    }

    &.layout-two_col .card-content {
        width: 49%;
        @include break(mobile){
            width: 100%;
        }
    }

    .container_btn {
        text-align: center;
    }
}