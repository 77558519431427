.module-28-ceo-letter {
    margin-top: 0;
    padding-top: 48px;

    &.layout-img-txt {
        .letter-img {
            order: 1;
        }

        .letter-content {
            order: 2;
        }
    }

    &.layout-txt-img {
        .letter-img {
            order: 2;

            @include break(tablet) {
                order: 1;
            }
        }

        .letter-content {
            order: 1;

            @include break(tablet) {
                order: 2;
            }
        }

        .letter {
            padding-left: calc(10% - 20px);
            padding-right: 0;

            @include break(small-screen) {
                padding-left: 8%;
            }

            @include break(tablet) {
                padding-left: 0;
            }
        }
    }

    &.bg_white {
        background-color: $white;
    }

    .letter {
        position: relative;
        padding-top: 140px;
        padding-bottom: 140px;
        padding-right: calc(10% - 20px);
        overflow: hidden;
        display: flex;
        gap: 85px;

        @include break(small-screen) {
            padding-top: 60px;
            padding-bottom: 60px;
            padding-right: 8%;
        }

        @include break(tablet) {
            padding-top: 40px;
            padding-bottom: 40px;
            padding-right: 0;
            flex-wrap: wrap;
        }

        .letter-img {
            width: auto;
            position: relative;

            @include break(tablet) {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 50%;
                max-width: none;
                max-height: none;
                margin: 0 auto 20px auto;
            }

            @include break(mobile) {
                width: 100%;
            }


            .shape {
                width: 30vw;
                margin-top: -200px;

                @include break(small-screen) {
                    margin-top: -115px;
                }

                @include break(tablet) {
                    width: 100%;
                    margin-top: -160px;
                }

                @include break(mobile) {
                    width: 80%;
                    margin-top: -160px;
                }

                .quote_icon {
                    width: 100%;
                }
            }

            .image {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 13vw;
                left: 7vw;
                z-index: 1;
                max-width: 314px;
                max-height: 314px;
                margin-top: -200px;

                @include break(small-screen) {
                    max-width: 230px;
                    max-height: 230px;
                    margin-top: -115px;
                }

                @include break(tablet) {
                    margin-top: -80px;
                    max-width: 250px;
                    max-height: 250px;
                    top: 12vw;
                    left: 50%;
                    transform: translateX(-50%);
                }

                @include break(mobile) {
                    max-width: 200px;
                    max-height: 200px;
                    top: 17vw;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;

                    @include break(tablet) {
                        max-height: inherit;
                    }
                }

                .letter-name {
                    text-align: center;
                    position: relative;
                    margin-top: 30px;
                    z-index: 1;

                    .name {
                        @include font-bold;
                        color: $pink;
                        font-size: 20px;
                        padding-bottom: 10px;

                        @include break(tablet) {
                            font-size: 1.1em;
                        }
                    }

                    .job {
                        font-size: 17px;

                        @include break(tablet) {
                            font-size: 1.1em;
                        }
                    }
                }
            }
        }

        .letter-content {
            position: relative;
            z-index: 2;

            @include break(tablet) {
                width: 90%;
                margin: 0 auto;
            }

            .txt {
                position: relative;
                z-index: 2;
                font-size: 1.15em;
                padding-bottom: 40px;

                @include break(tablet) {
                    font-size: 1.25em;
                    padding-bottom: 20px;
                }

                p {
                    margin-bottom: 1em;
                }
            }

            .signature {
                max-width: 150px;
                
                @include break(mobile) {
                    max-width: 80px;
                }
            }
        }
    }
}