.template-team {

    .module-team_text {
        .intro-text {
            padding-right: 20%;
            @include break(tablet){
                padding-right: 0%;
             }
        }
    }

    .module-team_list-name {
        p {
            padding-top: 20px;
            padding-bottom: 20px;
            @include break(tablet){
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
        .name {
            @include font-bold;
            font-size: 1.5em;
            padding-right: 40px;
            display: inline-block;
        }
    }

    .module-team_banner {
        background-position: center;
        background-size: cover;
        color: $white;
        text-align: center;
        position: relative;

        .container {
            position: relative;
            z-index: 2;
        }
    }

}