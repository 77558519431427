.module-18-testimonial {
    .testimony {
        position: relative;
        padding-top: 80px;
        padding-bottom: 80px;
        @include break(small-screen){
            padding-top: 60px;
            padding-bottom: 60px;
        }
        @include break(tablet){
            padding-top: 40px;
            padding-bottom: 40px;
        }

        .container {
            display: flex;
            justify-content: space-between;
            position: initial;
            @include break(tablet){
                flex-direction: column;
            }
            
            .testimony-img {
                z-index: 1;
                width: 33%;
                
                img {
                    width: 100%;
                    height: auto;
                }
                @include break(tablet){
                    width: 100%;
                    margin-bottom: 20px;
                }
            }
            .shape  {
                position: absolute;
                bottom:0;
                left: 0;
                z-index: 0;
                height: 100%;
                @include break(small-screen){
                    height: 80%;
                    left: -2.5%;
                }
                @include break(tablet){
                    display: none;
                }
            }

            .testimony-content {
                position: relative;
                z-index: 2;
                width: 70%;
                @include break(tablet){
                    width: 100%;
                }
                .txt {
                    position: relative;
                    z-index: 2;
                    font-size: 1.15em;
                    padding-bottom: 35px;
                    @include break(tablet){
                        font-size: 1.25em;
                        padding-bottom: 20px;
                    }

                    p {
                        display: inline;
                    }
                }
                .name, .job {
                    @include font-bold;
                    font-size: 1.33em;
                    @include break(tablet){
                        font-size: 1.1em;
                    }
                }

                .quote_icon {
                    position: absolute;
                    width: 90px;
                    top: -38px;
                    left: -58px;
                    z-index: 1;
                    
                    @include break(tablet){
                        left: -25px;
                    }
                }
            }
        }

        &.bg_sec-pink {
            svg path {
                fill: $pink;
            }
        }
        &.bg_sec-green {
            svg path {
                fill: $green;
            }
        }
        &.bg_sec-yellow {
            svg path {
                fill: $yellow;
            }
        }
        &.bg_sec-blue {
            svg path {
                fill: $blue;
            }
        }
        &.bg_sec-orange {
            svg path {
                fill: $orange;
            }
        }


        &.layout-img .testimony-content {
            width: 60%;
        }
    }
}