.module-bw-cpt-04_testimonial {
    
    .txt {
        font-size: 1.555em;
        line-height: 150%;

        p {
            display: inline;
        }
    }

    .name {
        @include font-bold;
        font-size: 1.1em;

        &:first-of-type {
            padding-top: 20px;
        }
    }

    .container {
        position: relative;

        .bg_shape {
            @include absolute-center(center);
            width: 85%;
        }
    }
    .testimonial_content {
        position: relative;
        z-index: 2;
    }


    &.color-pink {
        .txt, .name {
            color: $pink;
        }

        svg path {
            fill: $light-blue;
        }
    }
    &.color-green {
        .txt, .name {
            color: $green;
        }

        svg path {
            fill: $light-yellow;
        }
    }
    &.color-yellow {
        .txt, .name {
            color: $yellow;
        }

        svg path {
            fill: $light-green;
        }
    }
    &.color-blue {
        .txt, .name {
            color: $blue;
        }

        svg path {
            fill: $light-pink;
        }
    }
    &.color-orange {
        .txt, .name {
            color: $orange;
        }

        svg path {
            fill: $light-yellow;
        }
    }
}