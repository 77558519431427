.module-bw-cpt-11_cta-banner {

    .layout_line {
        display: flex;
        justify-content: space-between;  
        align-items: center;
        padding-bottom: 40px;
        border-bottom: 1px solid rgba(52, 52, 54, 0.08);
        @include break(mobile) {
           flex-direction: column;
           text-align: center;
        }

        .content {
            flex-shrink: 1;
            width: 100%;

            h4 {
                margin-bottom: 0;
            }
        }
        .btn_wrap {
            margin-left: 40px;
            flex-shrink: 0;
            @include break(mobile) {
                margin-top: 20px;
                width: 100%;
                margin-left: 0;
             }
            .btn {
                margin-top: 0;
            }

            .icon_social {
                text-decoration: none;
                margin-left: 10px;
                margin-right: 10px;
                display: inline-block;
                @include break(mobile) {
                    margin-left: 5px;
                    margin-right: 5px;
                    margin-top: 20px;
                }

                &:first-of-type {
                    margin-left: 0;
                    @include break(mobile) {
                        margin-left: 5px;
                    }
                }
                &:last-of-type {
                    margin-right: 0;
                    @include break(mobile) {
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .layout_box {
        text-align: center;
        padding: 50px 80px;
        border-radius: 40px;
        background-position: center;
        background-size: cover;
        position: relative;
        overflow: hidden;
        @include break(mobile) {
            padding: 25px;
        }

        .title-h3 {
            margin-bottom: 0;
        }

        .txt {
            margin-top: 16px;
        }

        .content {
            position: relative;
            z-index: 2;
        }
    }

}