html{
  overflow-x: hidden;
  overflow-y: scroll;
  &.overflow{
    overflow: hidden;
  }
  background-color: $pink;
  color: $dark;
  // &::-webkit-scrollbar { width: 0 !important };
  // overflow: -moz-scrollbars-none;
  // -ms-overflow-style: none;
  min-height: -webkit-fill-available;
}

body {
  padding-top: 96px;
  &.overflow{
    overflow: hidden;
  }
  background-color: $light-grey;
  color: $dark;
  font-size: $font-size;
  line-height: 150%;
  @include break(small-screen){
    padding-top: 80px;
  }
  @include break(mobile){
    padding-top: 70px;
    font-size: 16px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  @include font-regular;
  font-style: normal;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

// .loader {
  // position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  // height: 100vh;
  // z-index: 9999;
  // z-index: 9999999999;
  // background-color: #ffffff;
  // text-align: center;
// }

.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1172px;
}
.med_container {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  max-width: 970px;
  @include break(tablet){
      width: 90%;
  }
}
.small_container{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  max-width: 775px;
  @include break(tablet){
      width: 90%;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  >* {
    width: 50%;
  }
}

.mobile {
  display: none;
  @include break(mobile) {
    display: block;
  }
}
.desktop{
  @include break(mobile) {
    display: none;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}


h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
  a {
    color: inherit;
    text-decoration: none;
  }
}

a {
  color: inherit;
  transition: all 0.4s;
  &:hover {
    color: $pink;
  }
}

p {
  line-height: 150%;
}

.txt {
  img {
    width: 100%;
    height: auto!important;
  }
}

.intro-text {
  line-height: 150%;
  @include font-bold;
  font-size: 1.3em;
  @include break(mobile){
    font-size: 1.1em;
  }
}

.filter {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $dark;
  z-index: 0;
  pointer-events: none;
}

section {
  position: relative;
  margin-top: 120px;
  margin-bottom: 120px;
  @include break(mobile){
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .container {
    position: relative;
  }

  &.flex-block_first {
    margin-top: 0;
  }

  &:not(.bg_white) {
    overflow: hidden;
    padding-top: 120px;
    padding-bottom: 120px;
    @include break(mobile){
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  &.flex-block_last:not(.bg_white) {
    margin-bottom: 0;
    @include break(mobile){
      margin-bottom: 0;
    }
  }
}

.top_container {
  padding-bottom: 60px;
  @include break(mobile){
    padding-bottom: 30px;
  }
}

.align_center {
  text-align: center;
}

.align_left {
  text-align: left;
}
.align_right {
  text-align: right;
}

.hiddenText {
  position: absolute;
  z-index: -1;
  opacity: 0;
  height: 1px;
  width: 1px;
}

// TITLE

h1, .title-h1 {
  @include font-bold;
  // font-size: 5em;
  font-size: 4.2em;
  line-height: 108%;
  @include break(mobile){
    font-size: 2.9em;
  }
}

h2, .title-h2 {
  // font-size: 3em;
  font-size: 2.6em;
  line-height: 108%;
  @include font-bold;
  margin-bottom: 20px;

  @include break(mobile){
    font-size: 2.5em;
  }
}

h3, .title-h3 {
  // font-size: 2.35em;
  font-size: 2.25em;
  line-height: 108%;
  @include font-bold;
  margin-bottom: 16px;

  @include break(mobile){
    font-size: 2em;
  }

  &.subtitle {
    font-size: 1.2em;
  }
}

h4, .title-h4 {
  // font-size: 1.88em;
  font-size: 1.75em;
  line-height: 108%;
  @include font-bold;
  margin-bottom: 16px;

  @include break(mobile){
    font-size: 1.45em;
  }
}

h5, .title-h5 {
  // font-size: 1.6em;
  font-size: 1.55em;
  line-height: 108%;
  @include font-bold;
  margin-top: 0;
  margin-bottom: 16px;

  @include break(mobile){
    font-size: 1.25em;
  }
}

h6, .title-h6 {
  margin: 0;
  padding: 0;
  font-size: 1.33em;
  line-height: 108%;
  @include font-bold;
  margin-bottom: 16px;

  @include break(mobile){
    font-size: 1.15em;
  }
}



// BACKGROUND

.bg_pink {
  background-color: $pink;
  color: $white;

  a:hover {
    color:$light-pink;
  }
  .btn_light-pink {
    border: 2px solid $light-pink;
    padding: 14px 62px 14px 26px;
  }
  .btn_white, .btn_primary {
    border: 2px solid $white;
    padding: 14px 62px 14px 26px;
  }
}
.bg_sec-pink {
  background-color: $light-pink;
}
.bg_light-pink {
  background-color: rgba($light-pink, 0.2);
}

.bg_green {
  background-color: $green;
  color: $white;
  .btn_light-green {
    border: 2px solid $light-green;
    padding: 14px 62px 14px 26px;
  }
}
.bg_sec-green {
  background-color: $light-green;
}
.bg_light-green {
  background-color: rgba($light-green, 0.2);
}

.bg_yellow {
  background-color: $yellow;
  color: $dark;
  p {
    color: $dark;
  }
  .btn_light-yellow {
    border: 2px solid $light-yellow;
    padding: 14px 62px 14px 26px;
  }
}
.bg_sec-yellow {
  background-color: $light-yellow;
}
.bg_light-yellow {
  background-color: rgba($light-yellow, 0.2);
}

.bg_blue {
  background-color: $blue;
  color: $white;
  .btn_light-blue {
    border: 2px solid $light-blue;
    padding: 14px 62px 14px 26px;
  }
}
.bg_sec-blue {
  background-color: $light-blue;
}
.bg_light-blue {
  background-color: rgba($light-blue, 0.2);
}

.bg_orange {
  background-color: $orange;
  color: $white;
  .btn_light-orange {
    border: 2px solid $light-orange;
    padding: 14px 62px 14px 26px;
  }
}
.bg_sec-orange {
  background-color: $light-orange;
}
.bg_light-orange {
  background-color: rgba($light-orange, 0.2);
}

.txt-pink {
  color: $pink;
}
.txt-green {
  color: $green;
}
.txt-yellow {
  color: $yellow;
}
.txt-blue {
  color: $blue;
}
.txt-orange  {
  color: $orange;
}


// SHAPE
.img-shape_square {
  overflow: hidden;
  border-radius: 0;
}

.img-shape_round {
  overflow: hidden;
  border-radius: 50%;
}

.img-shape_arc {
  overflow: hidden;
  border-radius: 50% 50% 0 0;
}

.img-shape_leaf {
  overflow: hidden;
  border-radius: 0 50% 0 50%;
}


// POPUP
.popup_video {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 999999;
  align-items: center;
  justify-content: center;

  .inner {
    position: absolute;
    width: 80%;
    height: 80vh;
    transform-origin: center;
    display: flex;
    align-items: center;
    @include absolute-center(center);
    z-index: 2;
    pointer-events: none;
  }

  .video_wrap {
    pointer-events: all;
    width: 100%;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;

    iframe, video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 10px;
    cursor: pointer;
    z-index: 2;
  }

  .bg {
    background: rgba($dark, 0.9);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}


// BUTTON
.play {
  cursor: pointer;
  @include absolute-center(center);
  transition: all 0.4s;

  &:hover {
    transform: translate(-50%, -50%) scale(1.1);
  }
}

.icon_social {
  text-decoration: none;
  border-radius: 50%;
  display: inline-block;
  padding: 0;
  margin: 0;
  line-height: 0;
  width: 69px;
  height: 68px;
  position: relative;
  z-index: 2;
  
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    width: 69px;
    height: 68px;
  }
  circle, path {
    transition: all 0.4s;
  }

  &:hover {
    circle {
      fill: $pink;
    }
    path {
      fill: $white;
    }
  }
}

.bg_pink .icon_social {
  border: 4px solid #F0E5ED;
}



.btn {
  display: inline-flex;
  padding: 16px 64px 16px 28px;
  align-items: center;
  border-radius: 40px;
  text-decoration: none;
  color: $white;
  font-size: 16px;
  text-align: center;
  @include font-bold;
  line-height: 140%;
  letter-spacing: 0.96px;
  text-transform: uppercase;  
  margin-top: 40px;
  background-color: $pink;
  position: relative;
  transition: all 0.4s;

  &:hover {
    color: $white;
  } 

  &::after {
    content: " ";
    background-color: $white;
    height: 2px;
    width: 9px;
    border-radius: 0.5px;
    right: 28px;
    position: absolute;
    top: 45%;
    transform: translate(-50%, -50%) rotate(50deg);
    transition: all 0.4s;
  }
  &::before {
    content: " ";
    background-color: $white;
    height: 2px;
    width: 9px;
    border-radius: 0.5px;
    right: 28px;
    position: absolute;
    top: 55%;
    transform: translate(-50%, -50%) rotate(-50deg);
    transition: all 0.4s;
  }

  &_ddl {
    padding: 16px 28px 16px 28px;
    display: flex;
    justify-content: space-between;
    &::after, &::before {
      display: none;
    }

    svg path {
      fill: $white;
    }
  }

  &_pink {
    color: $white;
    background-color: $pink;

    &:hover {
      background-color: $dark-pink;
      color: $white!important;
      svg path {
        fill: $white;
      }
    }
  }
  &_light-pink {
    color: $pink;
    background-color: $light-pink;
    &::after, &::before {
      background-color: $pink;
    }
    svg path {
      fill: $pink;
    }

    &:hover {
      background-color: $pink;
      color: $white;
      &::after, &::before {
        background-color: $white;
      }
      svg path {
        fill: $white;
      }
    }
  }

  &_green {
    color: $white;
    background-color: $green;

    &:hover {
      background-color: $dark-green;
      svg path {
        fill: $white;
      }
    }
  }
  &_light-green {
    color: $green;
    background-color: $light-green;
    &::after, &::before {
      background-color: $green;
    }
    svg path {
      fill: $green;
    }

    &:hover {
      background-color: $green;
      color: $white;
      &::after, &::before {
        background-color: $white;
      }
      svg path {
        fill: $white;
      }
    }
  }

  &_yellow {
    color: $dark;
    background-color: $yellow;
    &::after, &::before {
      background-color: $dark;
    }
    svg path {
      fill: $dark;
    }

    &:hover {
      color: $dark;
      background-color: $dark-yellow;
      &::after, &::before {
        background-color: $dark;
      }
      svg path {
        fill: $dark;
      }
    }
  }
  &_light-yellow  {
    color: $dark ;
    background-color: $light-yellow;
    &::after, &::before {
      background-color: $dark;
    }
    svg path {
      fill: $dark;
    }

    &:hover {
      background-color: $yellow;
      color: $dark;
      &::after, &::before {
        background-color: $dark;
      }
      svg path {
        fill: $dark;
      }
    }
  }

  &_blue {
    color: $white;
    background-color: $blue;

    &:hover {
      background-color: $dark-blue;
      svg path {
        fill: $white;
      }
    }
  }
  &_light-blue  {
    color: $blue ;
    background-color: $light-blue;
    &::after, &::before {
      background-color: $blue;
    }
    svg path {
      fill: $blue;
    }

    &:hover {
      background-color: $blue;
      color: $white;
      &::after, &::before {
        background-color: $white;
      }
      svg path {
        fill: $white;
      }
    }
  }

  &_orange {
    color: $white;
    background-color: $orange;

    &:hover {
      background-color: $dark-orange;
      svg path {
        fill: $white;
      }
    }
  }
  &_light-orange  {
    color: $orange ;
    background-color: $light-orange;
    &::after, &::before {
      background-color: $orange;
    }
    svg path {
      fill: $orange;
    }

    &:hover {
      background-color: $orange;
      color: $white;
      &::after, &::before {
        background-color: $white;
      }
      svg path {
        fill: $white;
      }
    }
  }

  &_white ,&_primary{
    color: $pink;
    background-color: $white;
    &::after, &::before {
      background-color: $pink;
    }
    svg path {
      fill: $pink;
    }

    &:hover {
      background-color: $pink;
      color: $white!important;
      &::after, &::before {
        background-color: $white;
      }
      svg path {
        fill: $white;
      }
    }
  }
  &_grey{
    color: $pink;
    background-color: $grey;
    &::after, &::before {
      background-color: $pink;
    }
    svg path {
      fill: $pink;
    }

    &:hover {
      background-color: $pink;
      color: $white;
      &::after, &::before {
        background-color: $white;
      }
      svg path {
        fill: $white;
      }
    }
  }
  
  &_secondary {
    background-color: transparent;
    border: 2px solid $white;
    padding: 14px 62px 14px 26px;
    color: $white;
    &::after, &::before {
      background-color: $white;
    }
    svg path {
      fill: $white;
    }

    &:hover {
      background-color: $white;
      color: $pink;
      &::after, &::before {
        background-color: $pink;
      }
      svg path {
        fill: $pink;
      }
    }
  }
  &_sec_pink {
    background-color: transparent;
    border: 2px solid $pink;
    padding: 14px 62px 14px 26px;
    color: $pink;
    &::after, &::before {
      background-color: $pink;
    }
    svg path {
      fill: $pink;
    }

    &:hover {
      background-color: $pink;
      color: $white;
      &::after, &::before {
        background-color: $white;
      }
      svg path {
        fill: $white;
      }
    }
  }

}


// HEADER

.header_projects {
  width: 100%;
  margin-bottom: 120px;
  @include break(mobile){
    margin-bottom: 60px;
  }

  .img {
    width: 100%;
    height: 45vh;
    max-height: 500px;
    object-fit: cover;
    position: relative;
    @include break(mobile){
      height: 35vh;
    }

    .btn_arrow {
      position: absolute;
      bottom: 0;
      right: 5%;
      z-index: 2;
      transform: translateY(50%);
      @include break(mobile){
        display: none;
      }
    }
  }

  h1 {
    padding-top: 80px;
    padding-bottom: 22px;
    @include break(mobile){
      padding-top: 60px;
    }
  }
}

.header_article {
  position: relative;
  padding-top: 100px;
  margin-bottom: 120px;
  @include break(mobile){
    margin-bottom: 60px;
  }
  
  .breadcrumb {
    color: rgba($dark, 0.8);
    background-color: transparent;
    a {
      color: rgba($dark, 0.8);
    }
    li {
      color: rgba($dark, 0.8);
      ::before {
        color: rgba($dark, 0.8);
      }
    }
  }

  .content {
    padding-bottom: 60px;
    @include break(mobile){
      padding-bottom: 30px;
    }
    .date {
      font-size: 14px;
      color: rgba($dark, 0.6);
      margin-bottom: 24px;
      @include break(mobile){
        margin-bottom: 12px;
      }
    }
    .sub_title {
      margin-top: 24px;
      font-size: 1.1em;
      @include break(mobile){
        margin-top: 12px;
      }
    }
  }

  .img {
    width: 100%;
    // padding-bottom: 51%;
    // background-position: center;
    // background-size: cover;
  }
}

.breadcrumb {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.20);
  height: 54px;
  display: flex;
  align-items: center;
  color: $white;
  padding-left: 5%;
  padding-right: 5%;
  z-index: 2;
  overflow-x: scroll;
  overflow-y: hidden;
  &::-webkit-scrollbar { width: 0 !important };
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  padding-top: 16px;
  background-color: rgba($dark, 0.45);
  transition: all 0.4s;

  ul {
      list-style-type: none;
      display: flex;
      padding: 0;
      margin: 0;
      li {
          margin-left: 30px;
          position: relative;
          color: rgba($white, 0.8);
          font-size: 12px;
          @include font-medium;
          text-transform: uppercase;
          flex-shrink: 0;
          a {
              color: rgba($white, 0.8);
              text-decoration: none;
              &:hover {
                  color: $light-pink;
              }
          }

          &::before {
              content: "•";
              position: absolute;
              top: 50%;
              left: -15px;
              transform: translate(-50%, -50%);
              &:hover {
                  color: rgba($white, 0.8);
              }
          }
      }
      li:first-of-type {
          margin-left: 0;
          &::before {
              display: none;
          }
      }
  }

  &:hover {
    background-color: rgba($dark, 0.7);
  }

  &.no-bg {
    background-color: transparent!important;
  }
}


// FORM

form {
  position: relative;
  input, textarea, select {
    margin-top: 11px;
    appearance: none;
    border: none;
    border-radius: 4px;
    background: $white;
    width: 100%;
    padding: 15px 18px;
    margin-bottom: 5px;
    transition: all 0.4s;
    border: 2px solid $white;
      @include break(mobile){
        margin-top: 5px;
        font-size: 16px;
      }

    &:focus {
      outline: none;
      border: 2px solid $pink;
    }

    &[type='submit'] {
      padding: 16px 28px 16px 28px;
      align-items: center;
      border-radius: 40px;
      text-decoration: none;
      color: $white;
      font-size: 16px;
      text-align: center;
      @include font-bold;
      line-height: 140%;
      letter-spacing: 0.96px;
      text-transform: uppercase;  
      margin-top: 40px;
      background-color: $pink;
      border: none;
      display: inline-block;
      width: auto;
      cursor: pointer;
      &:hover, &:focus {
        background-color: $dark-pink;
      }
    }

    &[type='checkbox'] {
      width: 20px;
      height: 20px;
      border-radius: 2px;
      margin-top: 0px;
      margin-right: 10px;
      margin-bottom: 0;
      border: 3px solid $white;
      border: 1px solid $pink;

      &:focus, &:checked {
        border: 3px solid $white;
        background-color: $pink;
      }
    }

    &[type='radio'] {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-top: 0px;
      margin-right: 10px;
      margin-bottom: 0;
      border: 3px solid $white;
      border: 1px solid $pink;

      &:focus, &:checked  {
        border: 3px solid $white;
        background-color: $pink;
      }
    }

  }

  .select .wpcf7-form-control-wrap {
    position: relative;
    &::after {
      content: " ";
      background-color: #343436;
      height: 2px;
      width: 9px;
      border-radius: 0.5px;
      right: 28px;
      position: absolute;
      top: 52%;
      transform: translate(-50%, -50%) rotate(50deg);
      transition: all 0.4s;
      pointer-events: none;
    }
    &::before {
      content: " ";
      background-color: #343436;
      height: 2px;
      width: 9px;
      border-radius: 0.5px;
      right: 23px;
      position: absolute;
      top: 52%;
      transform: translate(-50%, -50%) rotate(-50deg);
      pointer-events: none;
    }
  }

  .wpcf7-checkbox, .wpcf7-radio {
    margin-top: 11px;
    display: block;
    @include break(mobile){
      margin-top: 5px;
    }

    label {
      display: flex;
      align-items: center;
      margin-right: 35px;
    }
    .wpcf7-list-item {
      margin: 0;
    }
  }

  .wpcf7-response-output {
    border: none!important;
    padding: 0!important;
  }

  p {
    margin-bottom: 30px;
    @include break(mobile){
      margin-bottom: 15px;
    }
  }

  &.sent .wpcf7-response-output {
    background-color: rgba($dark, 0.9);
    border: none;
    border-radius: 4px;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    margin-left: 0;
    margin-right: 0;
    padding: 20px 40px;
    position: absolute;
    bottom: -35px;
    top: -85px;
    left: -35px;
    right: -35px;
    bottom: -35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 35px!important;
    font-size: 2em;
    line-height: 150%;
  }
}


// CSS YANN
.txt {

  .caption {
    font-style: italic;
    font-size: 0.8em;
  }

  blockquote {
    font-size: 1.22em;
    text-align: center;
    font-style: italic;
  }
  .quote-source {
    font-size: 14px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .box-pink {
    background-color: rgba(255, 164, 223, 0.2);
    border-radius: 20px;
    padding: 20px;
  }

}


// SEARCH
.search.template-stories-archive {
  .page-title {
    text-align: center;
    padding-left: 5%;
    padding-right: 5%;
    span {
      color: $pink;
    }
  }
  .search-bar {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0;
    .seach_container {
      width: 100%;
      max-width: 970px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 40px;
      padding-left: 5%;
      padding-right: 5%;

      h4 {
        margin-bottom: 0;
        padding-bottom: 24px;
      }

      .search-bar {
        margin-top: 24px;
        margin-bottom: 50px;
        width: 100%; 
      }
      form >div {
        display: flex;
        align-items: baseline;
        width: 100%; 
        position: relative;
        input[type=text] {
          width: 100%; 
          border: 1px solid $pink;
          padding-left: 32px;
          padding-right: 220px;
          border-radius: 36px;
          height: 55px;
          background-color: $white;
          @include break(mobile){
            padding-left: 20px;
            padding-right: 20px;
            margin-right: 0;
          }
        }
        #searchsubmit {
          height: 55px;
          width: auto;
          position: absolute;
          top: 11px;
          margin: 0;
          right: 0;
          text-align: left;
          padding-right: 60px;
          @include break(mobile){
            top: 5px;
            color: $pink;
            padding: 0;
            width: 58px;
          }
        }

        .relevanssi-live-search-results-showing {
          top: 0!important;
          left: 20px!important;
          right: 20px!important;
          border-color: $pink!important;
          width: auto!important;
          margin-top: -5px;

          a {
            text-decoration: none;
            color: $pink;
            &:hover {
              color: $dark-pink;
            }
          }
        }
        .relevanssi-live-search-result-status {
          display: none!important;
        }
      }
      
      .form_wrap {
        position: relative;
        .icon {
          position: absolute;
          right: 20px;
          top: 54%;
          transform: translateY(-50%);
          z-index: 3;
          pointer-events: none;
          @include break(mobile){
            top: 50%;
            right: 16px;
          }
        }
      }

      h6 {
        padding-top: 55px;
        padding-bottom: 15px;
      }
      .tag_wrap {
        display: flex;

        .tag {
          margin-right: 10px;
          padding: 9px 14px;
          border-radius: 46px;
          background: rgba(154, 10, 128, 0.12);
          font-size: 12px;
          letter-spacing: 0.72px;
          text-transform: uppercase;
          @include font-bold;
          text-decoration: none;
          color: $pink;
          transition: all 0.3s;

          &:hover {
            background: $pink;
            color: $white;
          }
        }
      }
    }
  }
  form >div {
    display: flex;
    align-items: baseline;
    input {
      width: 100%; 
      flex-shrink: 1;
      border: 1px solid $pink;
      margin-right: 60px;
    }
    #searchsubmit {
      width: 200px;
    }
  }
  h4 {
    margin-bottom: 40px;
  }
  .page-nav {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;

   a.prev.page-numbers {
    margin-left: -30px;
   }
  }
}


// IMAGE
.img_capt {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #828283;
  padding-left: 8px;
  margin-top: 10px;
  border-left: 2px solid $pink;
}


.post-password-form {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1172px;
  padding-top: 100px;
  padding-bottom: 100px;
}

// Background image 
.bg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  picture {
      display: flex;
      height: 100%;
      width: 100%;
  }

  img {
      width: 100%;
      object-fit: cover;
  }
}