.module-bw-26-contact_hours {

    .container {
        display: flex;
        justify-content: space-between;
        @include break(mobile) {
            flex-direction: column;
        }
    }

    .col1 {
        width: 45%;
        @include break(mobile) {
            width: 100%;
        }
        
        .tab_nav-container {
            margin-top: 48px;
            width: 100%;
            overflow-x: scroll;
            overflow-y: visible;
            &::-webkit-scrollbar { width: 0 !important };
            overflow: -moz-scrollbars-none;
            -ms-overflow-style: none;
            @include break(mobile) {
                margin-top: 24px;
            }
    
            .tab_nav {
                display: flex;
                position: relative;
                padding-bottom: 12px;
                margin-bottom: 30px;
                border-bottom: 2px solid rgba(52, 52, 54, 0.20);
                width: max-content;
                min-width: 100%;
    
                .tab-name {
                    font-size: 16px;
                    @include font-bold;
                    line-height: 140%;
                    letter-spacing: 0.96px;
                    text-transform: uppercase;
                    margin-right: 32px;
                    color: rgba($dark, 0.6);
                    cursor: pointer;
                    transition: all 0.4s;
                    position: relative;
                    z-index: 2;
                    flex-shrink: 0;
        
                    &::after {
                        z-index: 2;
                        content: " ";
                        bottom: -14px;
                        background-color: $pink;
                        height: 2px;
                        width: 100%;
                        display: block;
                        position: absolute;
                        opacity: 0;
                        transition: all 0.4s;
                    }
        
                    &:hover {
                        color: $pink;
                    }
        
                    &.active {
                        color: $pink;
                        pointer-events: none;
                        &::after { 
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .tab_container {
            position: relative;
            width: 100%;
            height: 280px;
    
            .tab {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                display: none;
    
                &:first-of-type {
                    display: block;
                }
            }
        }
    }

    .col2 {
        width: 45%;
        @include break(mobile) {
            width: 100%;
            margin-top: 40px;
        }

        .top {
            h2 {
                margin-bottom: 0;
            }
            margin-bottom: 48px;
            @include break(mobile) {
                margin-bottom: 24px;
            }

            .btn {
                @include break(mobile) {
                    margin-top: 24px;
                }
            }
        }

        .box {
            margin-bottom: 25px;
            h6 {
                margin-bottom: 10px;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .icon_social {
            transform: scale(0.7);
            margin-left: -11px;
            border: 35px solid #F0E5ED;
        }
    }
}