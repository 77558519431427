.module-31-menu-anchors {
    overflow-anchor: none;
    overflow: initial !important;
    margin-top: 100px;
    padding-top: 88px;
    margin-bottom: 0 !important;
    position: sticky;
    position: -webkit-sticky;
    background-color: $white;
    top: 0;
    left: 0;
    padding-bottom: 20px !important;
    z-index: 99;
    transform: translateY(0px);
    transition: all 0.4s;

    &.fixed {
        position: fixed;
        top: 65px;
        padding-top: 30px;
        width: 100%;
    }

    @include break(tablet) {
        margin-top: 0;
        top: 65px;
        width: 100%;
    }

    >.container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include break(tablet) {
            flex-wrap: wrap;
            justify-content: center;
        }

        .btn {
            margin-top: 0;
            padding: 7px 46px 7px 25px;
            height: fit-content;

            &::before {
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.59994 0.60024C6.59994 0.268906 6.33107 0 5.99976 0C5.66845 0 5.39958 0.268894 5.39958 0.60024V6.35182L3.42469 4.37617C3.19013 4.14159 2.81047 4.14159 2.57591 4.37617C2.34192 4.61075 2.34192 4.99045 2.57591 5.22447L5.55877 8.20763C5.66789 8.32632 5.82539 8.40114 5.99976 8.40114C6.09032 8.40114 6.17582 8.38089 6.25232 8.34545C6.31475 8.3162 6.37325 8.27626 6.42443 8.22506L9.42413 5.22446C9.65869 4.99045 9.65869 4.61074 9.42413 4.37617C9.19014 4.14159 8.80991 4.14159 8.57592 4.37617L6.59987 6.35242L6.59994 0.60024ZM0.60014 7.19988C0.931441 7.19988 1.20032 7.46877 1.20032 7.80012V10.1999C1.20032 10.3591 1.26332 10.5121 1.37581 10.6246C1.48831 10.7371 1.64074 10.8001 1.79992 10.8001H10.2C10.3592 10.8001 10.5117 10.7371 10.6241 10.6246C10.7366 10.5121 10.7996 10.3591 10.7996 10.1999V7.80012C10.7996 7.46879 11.0685 7.19988 11.3998 7.19988C11.7311 7.19988 12 7.46877 12 7.80012V10.1999C12 10.6775 11.8104 11.1354 11.4724 11.4729C11.1349 11.8104 10.677 12 10.2001 12H1.79994C1.32296 12 0.865091 11.8104 0.527622 11.4729C0.18957 11.1354 0 10.6775 0 10.1999V7.80012C0 7.46879 0.268867 7.19988 0.60018 7.19988H0.60014Z' fill='%23333333'/%3E%3C/svg%3E");
                height: auto;
                width: auto;
                top: 50%;
                right: 20px;
                border-radius: 0;
                background-color: transparent;
                transform: translate(-50%, -50%);
            }
            &::after {
                display: none;
            }

            @include break(tablet) {
                margin-top: 10px;
            }
        }
    }

    .container_ancres {
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
        align-items: center;
        width: fit-content;
        max-width: 70%;
        padding-bottom: 0px;

        @include break(small-screen) {
            max-width: 90%;
        }

        @include break(tablet) {
            max-width: 100%;
            padding-bottom: 10px;
        }

        a {
            flex-shrink: 0;
            margin-left: 12px;
            margin-right: 12px;
            text-decoration: none;
            line-height: 140%;
            font-size: 0.888em;

            &:first-of-type {
                margin-left: 0;
            }
        }
    }
}