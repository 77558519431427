.module-bw-cpt-05_image-slider {

    .slider_arrow-left {
        opacity: 0.6;
        z-index: 5;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 2.5%;
        &:hover {
            opacity: 1;
        }
    }
    .slider_arrow-right {
        opacity: 0.6;
        z-index: 5;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 2.5%;
        &:hover {
            opacity: 1;
        }
    }

    .slider_container {
        position: relative;
    }
    
    .slider_wrapper {
        padding-bottom: 60%;
        position: relative;
    }

}