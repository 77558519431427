.module-20-logos {
    .top_container {
        padding-bottom: 0px;
        @include break(mobile){
            padding-bottom: 0px;
        }
    }
    .logo_container {
        margin-top: 80px;
        @include break(mobile){
            flex-direction: column;
            margin-top: 40px;
        }
        &:first-of-type {
            margin-top: 0;
        }
        

        .logo_text {
            text-align: center;
            margin-bottom: 20px;
            @include break(mobile){
                width: 100%;
                margin-bottom: 16px;
            }

            h6 {
                text-transform: uppercase;
                font-size: 16px;
                color: $pink;
                margin-bottom: 0;
            }
            .txt {
                margin-top: 12px;
            }
        }

        .logo_wrap {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            @include break(mobile){
                width: 100%;
            }
            &.container_size-large {
                justify-content: center;
            }
            .logo {
                width: 30%;
                margin-right: 1%;
                margin-left: 1%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                @include break(mobile){
                    margin-bottom: 15px;
                    margin-right: 0;
                }
                img {
                    width: 100%;
                    max-width: 100%;
                    height: auto;
                }

                &:last-child {
                    margin-right: 0;
                }

                &_ghost {
                    height: 0;
                    padding: 0;
                    margin: 0;
                }

                &.size_med {
                    width: 21%;
                }
                &.size_small {
                    width: 13%;
                    @include break(mobile){
                        width: 21%;
                    }
                }
            }
        }
    }
}