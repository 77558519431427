.module-25-contact_follow_us {

    .img_gallery {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .img {
            background-size: cover;
            background-position: center;
            flex-shrink: 0;
            margin-left: 0.25%;
            margin-right: 0.25%;

            // &:nth-child(4n + 1) {
            //     overflow: hidden;
            //     border-radius: 50% 50% 0 0;
            // }
            // &:nth-child(4n + 3) {
            //     overflow: hidden;
            //     border-radius: 50%;
            // }
            // &:nth-child(4n + 4) {
            //     overflow: hidden;
            //     border-radius: 0 50% 0 50%;
            // }
        }
    }

    &.layout-insta,
    &.layout-api {
        .img_gallery {
            overflow: hidden;
            width: 117.5%;
            margin-left: -8.75%;
            margin-right: -8.75%;

            .img {
                width: 16.5%;
                padding-bottom: 16.5%;
                position: relative;

                @include break(mobile) {
                    width: 24.5%;
                    padding-bottom: 24.5%;
                }

                &>a {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .insta_container {
            text-align: center;

            .btn {
                margin-top: 60px;

                @include break(mobile) {
                    margin-top: 25px;
                }
            }
        }

    }

    &.layout-all {

        .socials {
            padding-bottom: 60px;
            display: flex;
            justify-content: center;
            width: 100%;

            @include break(mobile) {
                padding-bottom: 20px;
            }

            .icon_social {
                margin-left: 12px;
                margin-right: 12px;

                @include break(mobile) {
                    transform: scale(0.65);
                }
            }
        }

        .img {
            width: 24%;
            padding-bottom: 24%;

            @include break(mobile) {
                width: 24.5%;
                padding-bottom: 24.5%;
            }
        }
    }

}