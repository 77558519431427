.module-12-action_img-cta {
    height: 90vh;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    @include break(mobile){
        height: auto;
        min-height: 45vh;
    }

    .container {
        position: relative;
        z-index: 2;

        .title-h2 {
            margin-bottom: 0;
        }
        .txt {
            margin-top: 20px;
        }
    }
}