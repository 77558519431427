.template-story {

    .header_article {
        margin-bottom: 30px;
        .breadcrumb:hover {
            background-color: rgba(52, 52, 54, 0);
        }
        .cat {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 6px 10px;
            border-radius: 4px;
            background-color: $light-green;
            color: $green;
            font-size: 14px;
            text-align: center;
            line-height: 150%;
            margin-bottom: 15px;
            transition: all 0.3s;
            text-decoration: none;

            &:hover {
                background-color: $green;
                color: $light-green;
                
            }
        }

        .date {
            padding-top: 35px;

            @include break(mobile) {
                padding-top: 15px;
            }
        }

        .img {
            position: relative;
            padding-bottom: 51%;
            background-position: center;
            background-size: cover;
            
            img {
                position: absolute;
                top:0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .page-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 120px;
        padding-top: 45px;
        @include break(tablet){
            padding-bottom: 60px;
            flex-direction: column;
        }

        .sidebar {
            position: relative;
            width: 400px;
            flex-shrink: 0;
            @include break(small-screen){
                width: 350px;
            }
            @include break(tablet){
                width: 100%;
                margin-top: 60px;
            }

            .sidebar_inner {
                border-radius: 40px 0px 0px 40px;
                background: rgba(126, 182, 226, 0.20);
                padding: 60px 60px 60px 40px;
                height: calc(100vh - 96px);
                position: sticky;
                top: 96px;
                left: 0;
                overflow-y: scroll;
                @include break(small-screen){
                    top: 80px;
                    height: calc(100vh - 80px);
                }
                @include break(tablet){
                    height: auto;
                    border-radius: 40px 40px 0px 0px;
                    padding: 50px 5%;
                }

                .link {
                    flex-shrink: 0;
                    margin-bottom: 25px;
                    font-size: 12px;
                    font-family: 'Ubuntu', sans-serif;
                    font-style: normal;
                    font-weight: 700;
                    letter-spacing: 0.96px;
                    text-transform: uppercase;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    text-decoration: none;

                    svg {
                        margin-left: 10px;
                        path{
                            transition: all 0.4s;
                        }
                    }

                    &:hover svg path{
                        fill: $pink;
                    }
                }

                .side_info {
                    margin-bottom: 52px;
                }

                .side_info-top {
                    display: flex;
                    align-items: flex-end;
                    padding-bottom: 15px;

                    h6 {
                        font-size: 1.3em;
                        margin-right: 16px;
                        margin-bottom: 0;
                    }

                    .copy_link {
                        display: flex;
                        font-size: 14px;
                        color: $pink;
                        text-transform: uppercase;
                        align-items: center;
                        line-height: 1em;
                        @include font-bold;
                        cursor: pointer;
                    }
                }

                .related_stories {
                    .rel_post {
                        display: flex;
                        align-items: flex-start;
                        text-decoration: none;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        border-bottom: 2px solid $white;
                        .img {
                            width: 55px;
                            height: 55px;
                            border-radius: 8px;
                            flex-shrink: 0;
                            margin-right: 16px;
                            position: relative;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                        .title {
                            font-size: 17px;
                            @include font-bold;
                            line-height: 150%;
                        }
                    }
                }

                .more_news {
                    // padding-top: 40px;

                    // .link {
                    //     width: 205px;
                    //     display: flex;
                    //     font-size: 16px;
                    //     align-items: center;
                    //     @include font-bold;
                    //     line-height: 140%;
                    //     letter-spacing: 0.96px;
                    //     text-transform: uppercase;
                    //     color: $pink;
                    //     text-decoration: none;
                    //     transition: all 0.3s;
                    //     svg {
                    //         width: 20px;
                    //         margin-left: 14px;
                    //         flex-shrink: 0;
                    //         path {
                    //             transition: all 0.3s;
                    //         }
                    //     }
                    //     &:hover {
                    //         color: $dark-pink;
                    //         svg path {
                    //             fill: $dark-pink;
                    //         }
                    //     }
                    // }

                    .btn {
                        font-size: 14px;
                        text-align: left;
                    }
                }

                .share {
                    padding-top: 40px;

                    .share_wrap {
                        display: flex;

                        .icon_social {
                            width: 40px;
                            height: 40px;
                            margin-right: 10px;
                            svg {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        >.content {
            padding-left: calc((100vw - 1172px) / 2);
            padding-right: calc((100vw - 1172px) / 2);
            width: 100%;
            flex-shrink: 1;
            overflow: hidden;
            @include break(small-screen){
                padding-left: 5%;
                padding-right: 5%;
            }
            @include break(tablet){
                width: 100%;
            }

            section {
                padding-bottom: 40px;
                margin-bottom: 0;
                margin-top: 0;
                padding-top: 0;
                
                &:not(.bg_white) {
                    padding-top: 0;
                    margin-top: 0;
                }

                &:first-of-type{
                    padding-top: 20px!important;
                    margin-top: 0!important;
                }
                &:last-of-type {
                    padding-bottom: 0;
                }
            }

            .container {
                width: 100%;
            }
            .top_container {
                padding-bottom: 20px;
            }

            h1, h2  {
                font-size: 1.85em;
            }
            h3 {
                font-size: 1.55em;
            }
            h4 {
                font-size: 1.4em;
            }
            h5 {
                font-size: 1.3em;
            }
            h6 {
                font-size: 1.1em;
            }
            


// MODULE STORIES

            .module-stories-02-high-text {
                .txt {
                    padding: 24px 32px;
                    border-radius: 40px;
                }
            }

            .module-03-editorial_text {
                img {
                    width: auto!important;
                    max-width: 100%!important;
                }

                .wp-caption {
                    width: 100%!important;
                    text-align: left;

                    .wp-caption-text {
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 150%;
                        color: #828283;
                        padding-left: 8px;
                        margin-top: 10px;
                        border-left: 2px solid #9A0A80;
                    }
                }
            }
            .module-stories-03-images {
                img {
                    width: auto!important;
                    max-width: 100%!important;
                }

                .slider-image {
                    position: relative;
                    padding-bottom: 69%;
                    width: 100%;

                    .slider-image_slide {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        display: none;
                        &:first-of-type {
                            display: block;
                        }

                        .img_wrap {
                            background-color: #F6EDF5;
                            width: 100%;
                            height: calc(100% - 30px);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            position: relative;

                            img {
                                width: 100%!important;
                                height: 100%;
                                object-fit: contain;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%,-50%);
                            }
                            
                        }
                    }

                    .arrow_left {
                        position: absolute;
                        top: calc(50% - 15px);
                        left: 26px;
                        transform: translateY(-50%);
                        cursor: pointer;

                        svg g {
                            transition: all 0.3s;
                            opacity: 0.6;
                        }

                        &:hover {
                            svg g {
                                opacity: 1;
                            }  
                        }

                        @include break(mobile) {
                            left: 10px;
                            svg {
                                width: 35px;
                            }
                        }
                    }
                    .arrow_right {
                        position: absolute;
                        top: calc(50% - 15px);
                        right: 26px;
                        transform: translateY(-50%);
                        cursor: pointer;

                        svg g {
                            transition: all 0.3s;
                            opacity: 0.6;
                        }

                        &:hover {
                            svg g {
                                opacity: 1;
                            }  
                        }

                        @include break(mobile) {
                            right: 10px;
                            svg {
                                width: 35px;
                            }
                        }
                    }
                }
            }


            .module-stories-04-quote {
                .quote_inner {
                    display: flex;

                    .mark {
                        width: 100px;
                        flex-grow: 0;
                        flex-shrink: 0;
                        svg {
                            width: 100%;
                        }

                        @include break(mobile){
                            width: 40px;
                        }
                    }

                    .quote_txt {
                        width: 100%;
                        flex-shrink: 1;
                        padding-left: 48px;
                        @include break(mobile){
                            padding-left: 20px;
                        }
                        
                        .txt {
                            font-size: 1.33em;
                        }

                        .source {
                            padding-top: 16px;
                            @include font-bold;
                        }

                    }
                }
            }


            .module-stories-05-cta {
                margin-top: 10px!important;
                padding-bottom: 40px;
                @include break(mobile){
                    margin-bottom: 60px;
                }

                .cta_inner {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    @include break(mobile){
                        flex-direction: column;
                    }
                }

                .title {
                    width: 100%;
                    flex-shrink: 1;
                    padding-right: 30px;
                    @include break(mobile){
                        padding-right: 0px;
                        text-align: center;
                    }

                    h4 {
                        margin-bottom: 0;
                    }
                }

                .btn_wrap {
                    flex-shrink: 0;

                    .btn {
                        margin-top: 0;
                        @include break(mobile){
                            margin-top: 30px;
                        }
                    }
                }
            }

        }

    }

    .module-08-action_subscription {
        margin-top: 0;
        .container {
            width: auto;
            padding: 50px 57px;
            margin-left: calc((100vw - 1172px) / 2);
            margin-right: calc(((100vw - 1172px) / 2) + 400px);

            @include break(small-screen){
                margin-left: 5%;
                margin-right: calc(5vw + 350px);
            }
            @include break(tablet){
                margin-top: 30px;
                width: 90%;
                margin-left: 5%;
                margin-right: 5%;
            }
        }
    }
}