.module-11-action_cards {
    .top_container {
        flex-direction: column;
    }
    .container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include break(mobile){
            flex-direction: column;
        }

        .card-link {
            width: 31.5%;
            text-decoration: none;
            @include break(mobile){
                width: 100%;
                margin-bottom: 40px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            &_image {
                width: 100%;
                background-position: center;
                background-size: cover;
                padding-bottom: 67%;
                margin-bottom: 16px;
                position: relative;

                picture {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                }

                .img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &_title-normal {
                font-size: 1.6em;
                line-height: 108%;
                @include font-bold;
                margin-bottom: 16px;

                @include break(mobile){
                    font-size: 1.25em;
                }
            }
            &_title-small {
                font-size: 1.4em;
                line-height: 108%;
                @include font-bold;
                margin-bottom: 16px;

                @include break(mobile){
                    font-size: 1.15em;
                }
            }

            .icon_ext {
                display: block;
                margin-top: 20px;
                circle, path {
                    transition: all 0.4s;
                }
            }

            &:hover {
                .icon_ext circle {
                    fill: $pink;
                }
                .icon_ext path {
                  fill: $white;
                }
            }

            &_ghost {
                height: 0;
                padding: 0;
                margin: 0;
                line-height: 0;
            }
        }
    }
}