/*  --- Variable ---  */
$font-size: 18px;

//COLOR
$dark: #343436;
$white: #ffffff;
$light-grey:#F8F7F7;
$grey: #EBEBEB;
$border-grey: #AAA8AA;


$pink: #9A0A80;
$light-pink: #FFA4DF;
$dark-pink: #751663;

$green: #1C632C;
$light-green: #CED976;
$dark-green: #174621;

$yellow: #FFC801;
$light-yellow: #FDEE88;
$dark-yellow: #E2B40D;

$blue: #005F82;
$light-blue: #7EB6E2;
$dark-blue: #053F55;

$orange: #D14900;
$light-orange: #FFA96E;
$dark-orange:#B04105;