@import 'partials/_mixin';
@import 'partials/_function';

@import "./base/variable";
@import "./base/wordpress";
@import './base/breakpoint';
@import "./base/reset.scss";
@import './base/normalize';
@import './base/easing';
@import './base/fonts';
@import './base/layout';

@import './common/header';
@import './common/footer';
@import "./common/general";
@import "./common/anim-class";
@import './common/module-popup.scss';

@import './templates/template-home';
@import './templates/template-home-brick-works';
@import './templates/template-team';
@import './templates/template-stories-archive';
@import './templates/quatrecentquatre';
@import './templates/template-conf-archive';
@import './templates/bw-flex-modules';
@import './templates/template-story';
@import './templates/template-annual-reports';
@import './templates/template-dashboard';

// MODULE
@import './modules/module-01-header';
@import './modules/module-02-editorial_image-text';
@import './modules/module-03-editorial_text';
@import './modules/module-04-editorial_multicolumn';
@import './modules/module-05-collapse_content';
@import './modules/module-06-collapse_faq';
@import './modules/module-07-action_donate';
@import './modules/module-08-action_subscription';
@import './modules/module-09-action_box';
@import './modules/module-10-action_line';
@import './modules/module-11-action_cards';
@import './modules/module-12-action_img-cta';
@import './modules/module-13-projects';
@import './modules/module-14-impact';
@import './modules/module-15-timeline';
@import './modules/module-16-slider_tab';
@import './modules/module-17-content_cards';
@import './modules/module-18-testimonial';
@import './modules/module-19-image_video';
@import './modules/module-20-logos';
@import './modules/module-21-stories';
@import './modules/module-22-contact_cards';
@import './modules/module-23-contact_map';
@import './modules/module-24-contact_form';
@import './modules/module-25-contact_follow_us';
@import './modules/module-26-iframe';
@import './modules/module-bw-26-contact_hours';
@import './modules/module-27-conferences';
@import './modules/module-28-ceo-letter';
@import './modules/module-29-annual-numbers';
@import './modules/module-30-board';
@import './modules/module-31-menu-anchors';
@import './modules/module-32-hero-landing';
@import './modules/module-33-2cols-landing';
@import './modules/module-34-boxes-landing';
@import './modules/module-35-map';

@import './modules/bw-cpt/module-bw-cpt-01_text';
@import './modules/bw-cpt/module-bw-cpt-02_info-banner';
@import './modules/bw-cpt/module-bw-cpt-03_iframe';
@import './modules/bw-cpt/module-bw-cpt-04_testimonial';
@import './modules/bw-cpt/module-bw-cpt-05_image-slider';
@import './modules/bw-cpt/module-bw-cpt-06_table';
@import './modules/bw-cpt/module-bw-cpt-07_collapse';
@import './modules/bw-cpt/module-bw-cpt-08_download-documents';
@import './modules/bw-cpt/module-bw-cpt-09_logo-box';
@import './modules/bw-cpt/module-bw-cpt-10_logo-list';
@import './modules/bw-cpt/module-bw-cpt-11_cta-banner';
@import './modules/bw-cpt/module-bw-cpt-12_faq';
@import './modules/bw-cpt/module-bw-cpt-13_redirection';