.module-13-projects {

    .card-project {
        width: 500px;
        height: 615px;
        border-radius: 12px;
        position: relative;
        overflow: hidden;
        color: $white;
        transition: none;
        @include break(small-height){
            width: 445px;
            height: 535px;
        }
        @include break(mobile){
            width: 306px;
            height: 382px;
        }

        .card-filter {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0.5;
            background: linear-gradient(180deg, rgba(52, 52, 54, 0.00) 0%, #343436 100%);
            z-index: 1;
        }
        &_top {
            background-position: center;
            background-size: cover;
            width: 100%;
            position: relative;
            height: 100%;
        }

        .arrow {
           position: absolute;
           top: 32px;
           right: 32px;
           z-index: 4;
           svg {
            width: 62px;
            height: 62px;
            circle, path {
                transition: fill 0.4s; 
            }
           }
           @include break(mobile){
                right: 26px;
                svg {
                    width: 52px;
                    height: 52px;
                }
            }
        }

        &_overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            padding: 40px 34px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            transition: background-color 0.4s;
            overflow-y: scroll;
            overflow-x: hidden;
            z-index: 2;
            
            .title-h2 {
                margin-bottom: 0;
                color: $white;
                position: relative;
                z-index: 2;
            }
        }

        .card-project_overlay-bg {
            position: absolute;
            width: 62px;
            height: 62px;
            background-color: $light-green;
            border-radius: 50%;
            top: 32px;
            right: 32px;
            transition: all 0.4s;
            z-index: 1;
        }

        &_txt {
           display: none;
           overflow: hidden;
           z-index: 2;
           position: relative;
        }
        .txt_date {
            margin-top: 10px;
        }

        &_txt-inner {
            padding-top: 10px;
        }

        &:hover {
            .card-project_overlay {
                color: $dark;

                .title-h2 {
                    color: $dark;
                }
            }
            .arrow {
                circle {
                    fill: $green;
                }
                path {
                    fill: $light-green;
                }
            }

            .card-project_overlay-bg {
                transform: scale(30);
            }
        }

        &_ghost {
            padding: 0!important;
            height: 0!important;
        }

        &_small {
            width: 375px;
            height: 465px;   
            @include break(mobile){
                width: 306;
                height: 382;
            }  
            
            &:hover {
                .card-project_overlay-bg {
                    transform: scale(20);
                }
            }
        }
    }
    
    &.layout-grid {
        .grid_container {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            
            .card-project {
                margin-bottom: 26px;
                margin-right: 13px;
                margin-left: 13px;
                &_small {
                    margin-right: 5px;
                    margin-left: 5px;  
                    margin-bottom: 10px;   
                }

                @include break(small-screen){
                   width: 46%;
                   max-width: 532px;
                   &_small {
                    width: 32%;
                   }
                }
                @include break(tablet){
                    width: 100%;
                    max-width: 532px;
                    &_small {
                     width: 100%;
                     max-width: 375px;
                    }
                 }
            }
        }
    }

    &.layout-slider {
        display: flex;
        @include break(tablet){
            flex-direction: column;
        }
        .left_container {
            margin-left: calc((100vw - 1172px) / 2);
            min-width: 300px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @include break(small-screen){
                margin-left: 5%;
            }
            @include break(tablet){
                margin-left: 5%;
                margin-right: 5%;
                width: 90%;
             }
        }
        .slider_wrap {
            width: 824px;
            flex-shrink: 0;
            height: 615px;
            position: relative;
            margin-left: 10%;
            overflow: hidden;
            @include break(small-screen){
                margin-left: 5%;
            }
            @include break(tablet){
                margin-top: 40px;
                margin-left: 0;
                width: 100%;
            }
            @include break(mobile){
                height: 382px;
            }
            
            .card-project {
                position: absolute;
                top: 0;
                left: 0;
                display: none;
                @include break(tablet){
                    width: 50%;
                    min-width: 306px;
                    left: 5%;
                }

                &:first-of-type {
                    display: block;
                }
                &:nth-of-type(2) {
                    display: block;
                    transform: translateX(105%);
                }
            }
        }
    }

    .slider_nav {
        @include break(tablet){
           margin-top: 40px;
           display: flex;
           justify-content: space-between;
           padding-left: 5%;
           padding-right: 5%;
         }

        svg {
            cursor: pointer;
            ellipse, path {
                transition: all 0.4s;
            }

            &:hover {
                ellipse{
                    fill-opacity: 1;
                } 
                path {
                    fill: $white;
                } 
            }
        }
        .arrow_left {
            margin-right: 8px;
        }

        &.desktop {
            @include break(tablet){
                display: none;
            } 
        }
        &.mobile {
            display: none;
            @include break(tablet){
                display: flex;
            } 
        }
    }

    .container_btn {
        text-align: center;
    }
    
}