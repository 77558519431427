.module-16-slider_tab {

    .tab-name_container {
        overflow-x: scroll;
        overflow-y: visible;
        &::-webkit-scrollbar { width: 0 !important };
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;
        
        .tab-name_container-inner {
            display: flex;
            position: relative;
            padding-bottom: 18px;
            margin-bottom: 30px;
            border-bottom: 2px solid rgba(52, 52, 54, 0.20);
            width: max-content;
            min-width: 100%;
        }

        // .line {
        //     position: absolute;
        //     height: 2px;
        //     bottom: 0;
        //     left: 0;
        //     right: 0;
        //     background-color: rgba(52, 52, 54, 0.20);
        // }

        .tab-name {
            font-size: 16px;
            @include font-bold;
            line-height: 140%;
            letter-spacing: 0.96px;
            text-transform: uppercase;
            margin-right: 32px;
            color: rgba($dark, 0.6);
            cursor: pointer;
            transition: all 0.4s;
            position: relative;
            z-index: 2;
            flex-shrink: 0;

            &::after {
                z-index: 2;
                content: " ";
                bottom: -20px;
                background-color: $pink;
                height: 2px;
                width: 100%;
                display: block;
                position: absolute;
                opacity: 0;
                transition: all 0.4s;
            }

            &:hover {
                color: $pink;
            }

            &.active {
                color: $pink;
                pointer-events: none;
                &::after { 
                    opacity: 1;
                }
            }
        }
    }

    .tab_container {
        position: relative;
        height: 555px;
    }

    .tab-slide {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: none;

        &:first-of-type  {
            display: block;
        }

        &_inner {
            display: flex;
            justify-content: space-between;
            @include break(tablet){
                flex-direction: column;
                align-items: center;
             }
        }

        .tab-slide_left {
            width: 100%;
            flex-shrink: 1;
            padding-right: 10%;
            @include break(tablet){
                padding-right: 0%;
             }

            .tab-cards_title-wrap {
                margin-top: 50px;
                p {
                    margin-bottom: 0.4em;  
                }
                .tab-cards_cta {
                    @include font-bold;
                    margin-bottom: 0.4em;
                    cursor: pointer;
                    &:hover {
                        color: $pink;
                    }
                    &.active {
                        color: $pink;
                        pointer-events: none;
                    }
                } 

                &.titles-big {
                    @include break(tablet){
                        display: none;
                     }
                    .tab-cards_cta {
                        font-size: 1.33em;
                        margin-bottom: 1.1em;
                    }
                }
            }
        }

        .tab-slide_right {
            flex-shrink: 0;
            height: 555px;
            width: 425px;
            position: relative;
            @include break(tablet){
                height: 478px;
                width: 375px;
            }

            .tab-card {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                display: none;

                &:first-of-type  {
                    display: flex;
                }

                .svg_bg {
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 1;
                    height: 100%;
                }

                .inner {
                    @include absolute-center(center);
                    width: 375px;
                    height: 465px;
                    padding: 32px 28px 84px 28px;
                    z-index: 2;
                    border-radius: 20px;
                    display: flex;
                    flex-direction: column;
                    @include break(tablet){
                        width: 335px;
                        height: 420px;
                    }
                    .title-card {
                        flex-shrink: 0;
                    }
                    .txt {
                        flex-shrink: 1;
                        overflow-x: hidden;
                        overflow-y: scroll;
                    }
                    .link {
                        flex-shrink: 0;
                        margin-top: 25px;
                        font-size: 16px;
                        @include font-bold;
                        letter-spacing: 0.96px;
                        text-transform: uppercase;
                        display: flex;
                        align-items: center;
                        text-decoration: none;
                        svg {
                            margin-left: 14px;
                            path {
                                transition: all 0.4s;
                            }
                        }
                    }
                }
                .slider_nav {
                    position: absolute;
                    z-index: 5;
                    bottom: 32px;
                    left: 50%;
                    transform: translate(-50%);
                    display: flex;
                    justify-content: space-between;
                    width: 70px;
                    svg {
                        cursor: pointer;
                        path {
                            transition: all 0.4s;
                        }
                    }
                }

                &_color-pink {
                    .svg_bg path {
                        fill: $light-pink;
                    }
                    .link:hover {
                        color: $light-pink;
                        svg path {
                            fill: $light-pink;
                        }
                    }
                    .tab-card_arrow:hover path {
                        fill: $light-pink;
                    }
                }
                &_color-green {
                    .svg_bg path {
                        fill: $light-green;
                    }
                    .tab-card_arrow:hover path {
                        fill: $light-green;
                    }
                    .link:hover {
                        color: $light-green;
                        svg path {
                            fill: $light-green;
                        }
                    }

                }
                &_color-blue {
                    .svg_bg path {
                        fill: $light-blue;
                    }
                    .tab-card_arrow:hover path {
                        fill: $light-blue;
                    }
                    .link:hover {
                        color: $light-blue;
                        svg path {
                            fill: $light-blue;
                        }
                    }
                }
                &_color-yellow {
                    .svg_bg path {
                        fill: $light-yellow;
                    }
                    .tab-card_arrow:hover path {
                        fill: $light-yellow;
                    }
                    .link:hover {
                        color: $light-yellow;
                        svg path {
                            fill: $light-yellow;
                        }
                    }
                }
                &_color-orange {
                    .svg_bg path {
                        fill: $light-orange;
                    }
                    .tab-card_arrow:hover path {
                        fill: $light-orange;
                    }
                    .link:hover {
                        color: $light-orange;
                        svg path {
                            fill: $light-orange;
                        }
                    }
                }
                
            }
        }
    }
}