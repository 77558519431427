.module-08-action_subscription {
    .container {
        border-radius: 60px;
        padding: 72px 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        @include break(mobile){
            flex-direction: column;
            border-radius: 30px;
            padding: 35px 50px;
        }

        .content {
            width: 65%;
            @include break(mobile){
                width: 100%;
            }
        }

        .btn {
            margin-top: 0;
            @include break(mobile){
                margin-top: 20px;
            }
        }

        .arcs {
            position: absolute;
            right: -50px;
            top: -50px;
            @include break(mobile){
                right: -19px;
                top: -52px;
                width: 50px;
            }
        }
        
    }
}