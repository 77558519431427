.module-bw-cpt-03_iframe {
    
    .iframe_wrap {
        pointer-events: all;
        width: 100%;
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;

        iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        }
    }
}