
//FONT
@mixin font-regular{
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 400;
}
@mixin font-medium{
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 500;
}
@mixin font-bold{
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 700;
}
