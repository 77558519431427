.module-19-image_video {
    .slider_wrapper {
        position: relative;
        width: 100%;
        padding-bottom: 50%;
        .slide {
            display: none;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-position: center;
            background-size: cover;
            flex-direction: column;
            justify-content: flex-end;
            z-index: 1;

            &:first-of-type {
                display: flex;
                opacity: 1;
            }

            picture {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }

            .img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .content {
                position: initial;
                z-index: 2;
                padding-bottom: 65px;
                @include break(mobile){
                    padding-bottom: 0;
                }
                h3 {
                    color: $white;
                    width: 50%;
                    @include break(mobile){
                        font-size: 1.5em;
                    }
                }
            }

            .filter {
                background: linear-gradient(180deg, rgba(52, 52, 54, 0.00) 58.85%, #343436 100%);
                mix-blend-mode: multiply;
            }

            .popup_click {
                cursor: pointer;
                @include absolute-center(center);
                @include break(mobile){
                    .play {
                        width: 55px;
                    }
                }
            }

            .slide_caption {
                position: absolute;
                bottom:0;
                right: 0;
                color: $white;
                background-color: $pink;
                padding: 12px;
                font-size: 14px;
                @include font-bold;
                line-height: 1em;
                @include break(mobile){
                    padding: 7px;
                    font-size: 10px;
                    bottom: 0;
                    transform: translateY(100%);
                }
            }
        }

    }
    
    .slider_nav {
        @include break(mobile){
            position: relative!important;
            right: 0!important;
            padding-left: 5%;
            padding-right: 5%;
            display: flex;
            justify-content: space-between;
            bottom: auto!important;
            padding-top: 80px;
        }
        svg {
            cursor: pointer;
            ellipse, path {
                transition: all 0.4s;
            }

            &:hover {
                ellipse {
                    fill: $pink;
                }
                path {
                    fill: $white;
                }
            }
        }
    }
    .slider_container.full {
        .slider_nav {
            z-index: 5;
            position: absolute;
            bottom: 65px;
            right: calc((100vw - 1172px) / 2);
            @include break(small-screen) {
                right: 5%;
            }

            .slider_arrow-left {
                margin-right: 8px;
            }
        }
    }

    .slider_container.container {
        .slider_arrow-left {
            opacity: 0.6;
            z-index: 5;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 2.5%;
            &:hover {
                opacity: 1;
            }
        }
        .slider_arrow-right {
            opacity: 0.6;
            z-index: 5;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 2.5%;
            &:hover {
                opacity: 1;
            }
        }
    }
}