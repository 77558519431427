.sup-view.template-annual-reports {
    .header_home {
        background-position: center;
        background-size: cover;
        height: 75vh;
        position: relative;

        @include break(tablet) {
            height: 70vh;
        }

        .content {
            position: relative;
            z-index: 2;
            padding-top: 13vh;
            color: $white;
            width: 50%;

            @include break(small-screen) {
                width: 80%;
            }

            @include break(mobile) {
                width: 100%;
            }
        }
    }

    .sub-text .intro-text {
        padding: 30px 20%;
        text-align: center;
        color: $white;
        // margin-bottom: 120px;

        @include break(mobile) {
            padding: 10px 5%;
            margin-bottom: 60px;
        }
    }

    .module-07-action_donate {
        .content {
            .btn {
                background-color: $light-yellow;
                color: $dark;

                &::before,
                &::after {
                    background-color: $dark;
                }

                &:hover {
                    background-color: $pink;

                    &::before,
                    &::after {
                        background-color: $white;
                    }
                }
            }
        }
    }

    .module-15-timeline {
        &.layout-card {
            .timeline_card {
                &-txt {
                    @include break(mobile) {
                        opacity: 0;
                        transform: translateY(50px);
                    }
                }
                
                &-overlay {
                    @include break(mobile) {
                        opacity: 0;
                        transform: translateY(100%);
                    }
                }
            }
        }
    }

}