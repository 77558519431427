.module-23-contact_map {

    .map_container {
        position: relative;

        .map {
            width: 100%;
            height: 100vh;
            position: relative;
            overflow: hidden;
            @include break(tablet){
                height: 50vh;
            }
            
            iframe {
                pointer-events: none;
                position: absolute;
                top: -40px;
                left: 0;
                right: -52px;
                bottom: -60px;
                width: calc(100% + 52px);
                height: calc(100% + 100px);
            }
        }

        .btn {
            position: absolute;
            bottom: 140px;
            left: calc(50% + (505px / 2));
            transform: translateX(-50%);
            width: max-content;
            max-width: 90%;
            @include break(tablet){
                left: 50%;
                bottom: 70px;
            }
        }

        .sidebar {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            border-radius: 0px 60px 60px 0px;
            width: 505px;
            z-index: 2;
            padding: 100px 125px 100px 135px;
            overflow-x: hidden;
            overflow-y: scroll;
            &::-webkit-scrollbar { width: 0 !important };
            overflow: -moz-scrollbars-none;
            -ms-overflow-style: none;

            @include break(small-height){
                padding: 60px 125px 60px 135px;
            }

            @include break(tablet){
            position: relative;
            width: 100%;
            height: auto;
            padding: 40px 5% 40px 5%;
            border-radius: 30px 30px 0px 0px;
            margin-top: -30px;
            }

            h6 {
                @include font-bold;
                font-size: 14px;
                line-height: 150%;
                text-transform: uppercase;
                @include break(tablet){
                    margin-bottom: 10px;
                }
            }

            .txt {
                line-height: 150%;
            }

            .box {
                margin-bottom: 50px;
                @include break(small-height){
                    margin-bottom: 30px;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
                @include break(tablet){
                    margin-bottom: 30px;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }

            a {
                text-decoration: none;
            }

            .icon_social {
                transform: scale(0.7);
                margin-left: -11px;
                border: 35px solid #F0E5ED;
            }

        }

    }

}