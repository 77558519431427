.module-07-action_donate {

    .container {
        width: 95%;
        max-width: 1600px;
        display: flex;
        overflow: hidden;

        @include break(tablet) {
            flex-direction: column;
        }

        .img {
            width: calc(100% - 505px);
            flex-shrink: 0;
            border-radius: 60px 0 0 60px;
            background-position: center;
            background-size: cover;

            @include break(tablet) {
                width: 100%;
                border-radius: 60px 60px 0 0;
                height: 300px;
            }

            @include break(mobile) {
                height: 200px;
                border-radius: 30px 30px 0 0;
            }

            picture {
                display: flex;
                height: 100%;

                .img {
                    width: 100%;
                }
            }
        }

        .content {
            position: relative;
            width: 565px;
            margin-left: -60px;
            flex-shrink: 0;
            border-radius: 60px;
            padding: 60px 114px 60px 74px;
            font-size: 0.875em;

            @include break(tablet) {
                width: 100%;
                margin-left: 0px;
                margin-top: -60px;
            }

            @include break(mobile) {
                border-radius: 30px;
                margin-top: -30px;
                padding: 30px 57px 30px 37px;
            }

            a,
            .txt,
            h3 {
                position: relative;
                z-index: 1;
            }

            .shape {
                position: absolute;
                right: 40px;
                bottom: 40px;
                width: 147px;
                z-index: 0;

                @include break(mobile) {
                    right: -5px;
                    bottom: 0px;
                    transform: scale(0.6);
                }
            }
        }
    }
}