.template-home-secondary {
    .header_home {
        background-position: center;
        background-size: cover;
        height: 80vh;
        position: relative;
        margin-bottom: 120px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $white;
        @include break(tablet){
            height: 70vh;
            margin-bottom: 60px;
        }

        .container {
            position: relative;
            z-index: 2;
        }

        .filter {
            background: $dark;
            mix-blend-mode: multiply;
        }

        .svg_arrow {
            z-index: 2;
            position: absolute;
            left: 50%;
            bottom: 42px;
            transform: translateX(-50%);
        }
    }
}