.template-dashboard {

    .mozaique {

        .container {
            max-width: 1172px;
            @include break(small-screen) {
                max-width: 972px;
            }
            @include break(tablet) {
                max-width: 740px;
            }

            .row {
                display: flex;
                margin-bottom: 25px;
                font-size: 0.9em;
                @include break(small-screen) {
                    font-size: 0.75em;
                }
                @include break(tablet) {
                    font-size: 0.7em;
                }
                @include break(mobile) {
                    font-size: 1em;
                    flex-direction: column;
                    align-items: center;
                }

                .col_t1 {
                    width: 33%;
                    @include break(mobile) {
                        width: 100%;
                    }
                }

                .col_t2 {
                    width: 66%;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    @include break(mobile) {
                        width: 100%;
                    }
                }

                &.row_type1 {
                    .col_t1 {
                        padding-right: 24px;
                        @include break(mobile) {
                            padding-right: 0px;
                            margin-bottom: 25px;
                        }
                    } 
                    .col_t2 {
                        .smallbox {
                            margin-bottom: 12px;
                            @include break(mobile) {
                                margin-bottom: 25px;
                            }
                        }
                        .imagebox {
                            margin-bottom: 12px;
                            @include break(mobile) {
                                margin-bottom: 25px;
                            }
                        }
                        .longbox {
                            margin-top: 12px;
                            @include break(mobile) {
                                argin-bottom: 25px;
                            }
                        }
                    } 
                }

                &.row_type2 {
                    .col_t1 {
                        padding-left: 24px;
                        @include break(mobile) {
                            padding-left: 0px;
                            margin-bottom: 25px;
                        }
                    } 
                    .col_t2 {
                        .smallbox {
                            margin-top: 12px;
                            @include break(mobile) {
                                margin-top: 0;
                                margin-bottom: 25px;
                            }
                        }
                        .imagebox {
                            margin-top: 12px;
                            @include break(mobile) {
                                margin-top: 0;
                                margin-bottom: 25px;
                            }
                        }
                        .longbox {
                            margin-bottom: 12px;
                            @include break(mobile) {
                                margin-bottom: 25px;
                            }
                        }
                    } 
                }
            }

            .open_pop-dash {
                cursor: pointer;
            }

            .hover {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 2;
                opacity: 0;
                transition: all 0.4s;
            }

            .content {
                position: relative;
                z-index: 3;
                padding: 35px 25px;
                transition: all 0.4s;
                overflow-y: scroll;
                overflow-x: hidden;
                h3 {
                    transition: all 0.4s;
                }
                @include break(small-screen) {
                    padding: 30px 20px;
                }
            }

            .bigbox {
                height: 680px;
                position: relative;
                overflow: hidden;
                border-radius: 12px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                @include break(small-screen) {
                    height: 580px;
                }
                @include break(mobile) {
                    min-height: 480px;
                    height: auto;
                    width: 100%;
                    max-width: 375px;
                    margin-left: auto;
                    margin-right: auto;
                }

                .bg_img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 0;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        @include absolute-center(center);
                    }
                }
                .filter {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    opacity: 0.5;
                    z-index: 1;
                    background: linear-gradient(180deg, rgba(52, 52, 54, 0.00) 0%, #343436 100%);
                }
    
                .content {
                    color: $white;
                    h3 {
                        color: $white;
                    }
                }
                
                &:hover .hover{
                    opacity: 1;
                }

                &.color_yellow:hover {
                    h3, .content {
                        color: $dark!important;
                    }
                }
            }

            .smallbox {
                border-radius: 20px;
                height: calc(60% - 12px);
                position: relative;
                width: calc(50% - 12px);
                overflow: hidden;
                @include break(mobile) {
                    height: auto;
                    width: 100%;
                    max-width: 375px;
                    margin-left: auto;
                    margin-right: auto;
                }
                &:hover {
                    .hover{
                        opacity: 1;
                    }
                    h3, .content {
                        color: $white;
                    }
                }
                &.color_yellow:hover {
                    h3, .content {
                        color: $dark;
                    }
                }
            }

            .imagebox {
                width: calc(50% - 12px);
                height: calc(60% - 12px);
                overflow: hidden;
                position: relative;
                @include break(mobile) {
                    height: auto;
                    width: 100%;
                    margin-left: auto;
                    margin-right: auto;
                    padding-bottom: 100%;
                }
                .bg_img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 0;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        @include absolute-center(center);
                    }
                }
                
                &:hover .hover{
                    opacity: 1;
                }
            }

            .longbox {
                height: calc(40% - 12px);
                border-radius: 20px;
                overflow: hidden;
                position: relative;
                width: 100%;
                flex-shrink: 0;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                @include break(mobile) {
                    height: auto;
                    width: 100%;
                    margin-left: auto;
                    margin-right: auto;
                }
                &:hover {
                    .hover{
                        opacity: 1;
                    }
                    h3, .content {
                        color: $white;
                    }
                }

                &.color_yellow:hover {
                    h3, .content {
                        color: $dark;
                    }
                }

                svg {
                    height: 70px;
                    width: auto;
                    margin-bottom: 5px;
                    @include break(small-screen) {
                        height: 50px;
                    }
                }

                &.color_pink svg path {
                    fill: $pink!important;
                }
                &.color_blue svg path {
                    fill: $blue!important;
                }
                &.color_green svg path {
                    fill: $green!important;
                }
                &.color_yellow svg path {
                    fill: $yellow!important;
                }
                &.color_orange svg path {
                    fill: $orange!important;
                }
            }


        }

        .module-09-action_box {
            margin-top: 0;
            margin-bottom: 25px;
            @include break(mobile) {
                margin-top: -25px;
            }
            .container {
                max-width: 100%;
                width: 100%;
                border-radius: 20px;
            }
        }

    }

}