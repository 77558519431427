.module-35-map {
    margin-top: -60px;
    padding-top: 0;
    padding-bottom: 0;
    
    .hotspot_inner {
        position: relative;
    }

    .img_container {
        width: 100%;
        img {
            width: 100%;
            height: auto;
        }
    }

    .hotspot {
        transform: translate(-50%, -50%);
        position: absolute;
        &.open {
            z-index: 4;
        }
        
        .icon {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            cursor: pointer;
            transition: all 0.4s;
            @include break(mobile) {
                width: 32px;
                height: 32px;
            }
        
            .plus {
                width: 22px;
                height: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                @include absolute-center(center);
                transition: all 0.5s;
                @include break(mobile) {
                    height: 18px;
                    width: 18px;
                }
                
                div {
                    background-color: $white;
                    width: 100%;
                    height: 2px;
                    @include absolute-center(center);

                    &.line1 {
                        transform: translate(-50%,-50%) rotate(90deg);
                        transition: all 0.4s;
                    }
                }
            }

            svg {
                height: 22px;
                width: auto;
                max-width: 26px;
                @include absolute-center(center);
                transition: all 0.5s;
                path {
                    fill: $white;
                }
                @include break(mobile) {
                    height: 18px;
                }
            }


            &.bg_yellow {
                svg {
                    path {
                        fill: $dark;
                    }
                }

                .plus {
                    div {
                        background-color: $dark;
                    }
                }
            }

            &:hover, &.open {
                background-color: $white;
                .plus, svg {
                    transform: translate(-50%,-50%) scale(1.18);
                }
                &.bg_pink {
                    svg {
                        path {
                            fill: $pink;
                        }
                    }
                    .plus {
                        div {
                            background-color: $pink;
                        }
                    }
                }
                &.bg_blue {
                    svg {
                        path {
                            fill: $blue;
                        }
                    }
                    .plus {
                        div {
                            background-color: $blue;
                        }
                    }
                }
                &.bg_green {
                    svg {
                        path {
                            fill: $green;
                        }
                    }
                    .plus {
                        div {
                            background-color: $green;
                        }
                    }
                }
                &.bg_orange {
                    svg {
                        path {
                            fill: $orange;
                        }
                    }
                    .plus {
                        div {
                            background-color: $orange;
                        }
                    }
                }
                &.bg_yellow {
                    background-color: $dark;
                    svg {
                        path {
                            fill: $yellow;
                        }
                    }
                    .plus {
                        div {
                            background-color: $yellow;
                        }
                    }
                }
            }

            &:hover {
                transform: scale(1.1);
            }

            &.open .plus div.line1 {
                transform: translate(-50%,-50%) rotate(0deg);
            }
        }


        .content_box {
            padding: 22px 18px;
            font-size: 12px;
            border-radius: 20px;
            position: absolute;
            width: max-content;
            height: max-content;
            max-width: 335px;
            max-height: 200px;
            display: flex;
            flex-direction: column;
            display: none;
            h6 {
                flex-shrink: 0;
            }
            .txt {
                flex-shrink: 1;
                height: max-content;
                overflow-y: auto;
            }

            .link {
                font-size: 10px;
                @include font-bold;
                line-height: 140%;
                letter-spacing: 0.6px;
                text-transform: uppercase;
                cursor: pointer;
                transition: all 0.4s;
                margin-top: 15px;
            }

            &.pos-top_bottom {
                top: calc(100% + 10px);
            }
            &.pos-top_top {
                top: -10px;
                transform: translateY(-100%);
            }

            &.pos-left_left {
                right: 0
            }
            &.pos-left_right {
                left: 0;
            }

            &.bg_pink .link:hover {
                color: $light-pink;
            }
            &.bg_blue .link:hover {
                color: $light-blue;
            }
            &.bg_green .link:hover {
                color: $light-green;
            }
            &.bg_yellow .link:hover {
                color: $light-yellow;
            }
            &.bg_orange .link:hover {
                color: $light-orange;
            }
        }
    }
    .hotspots_mobile {
        margin-top: 15px;
        display: none;
        @include break(mobile) {
            display: block;
        }
    }
    .hotspot_mobile {
        font-size: 16px;
        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 15px;
            padding-bottom: 15px;
            margin-left: 5%;
            margin-right: 5%;
            width: 90%;
            border-bottom: 1px solid #E9EDF2;

            h6 {
                margin-bottom: 0;
                flex-shrink: 1;
            }

            .plus {
                height: 18px;
                width: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                transition: all 0.5s;
                margin-left: 20px;
                flex-shrink: 0;
                transition: all 0.4s;
                
                div {
                    background-color: $dark;
                    width: 100%;
                    height: 2px;
                    @include absolute-center(center);

                    &.line1 {
                        transform: translate(-50%,-50%) rotate(90deg);
                    }
                }
            }
        }

        .drop {
            overflow: hidden;
            height: 0;
            display: none;
            .content_drop {
                padding: 20px 5%;

                .link {
                    font-size: 14px;
                    @include font-bold;
                    line-height: 140%;
                    letter-spacing: 0.6px;
                    text-transform: uppercase;
                    cursor: pointer;
                    transition: all 0.4s;
                    margin-top: 10px;
                }
            }
        }

        &.open .plus div.line1 {
            transform: translate(-50%,-50%) rotate(0deg);
        }
    }
}


.popup_dash {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: none;

    .bg {
        background: rgba($dark, 0.9);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
      }

      .popup_container {
        background-color: $white;
        position: absolute;
        top: 20px;
        bottom: 20px;
        right: 20px;
        border-radius: 20px;
        width: 675px;
        z-index: 2;
        @include break(mobile) {
            top: 0px;
            bottom: 0px;
            right: 0px;
            left: 0px;
            width: 100%;
            border-radius: 0;
        }

        .popup_inner {
            overflow-y: auto;
            overflow-x: hidden;
            padding: 65px 25px;
            height: 100%;
        }

        .close_container {
            position: absolute;
            top: 0px;
            right: 0px;
            left: 0;
            cursor: pointer;
            padding: 10px;
            display: flex;
            justify-content: flex-end;
            background-color: $white;
            border-radius: 20px 20px 0px 0px;
            z-index: 4;
            @include break(mobile) {
                border-radius: 0px;
            }
        }

        .popup_module {
            margin-bottom: 40px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .popup_module-01-text {
            .btn {
                margin-top: 25px;
            }
        }

        .popup_module-02-image-video {
            position: relative;
            img {
                width: 100%;
            }
            .caption {
                    position: absolute;
                    bottom:0;
                    right: 0;
                    color: $white;
                    background-color: $pink;
                    padding: 8px;
                    font-size: 10px;
                    opacity: 0.7;
                    @include font-bold;
                    line-height: 1em;
                    transition: 0.4s;
                    @include break(mobile){
                        padding: 7px;
                        font-size: 10px;
                        bottom: 0;
                        transform: translateY(100%);
                    }
                &:hover {
                    opacity: 1;
                }
            }
        }

        .popup_module-03-image-video-2col {
            display: flex;
            justify-content: space-between;

            .col {
                width: 47.5%;
                position: relative;
                .play {
                    width: 80px;
                }
            }

            img {
                width: 100%;
            }
            .caption {
                    position: absolute;
                    bottom:0;
                    right: 0;
                    color: $white;
                    background-color: $pink;
                    padding: 8px;
                    font-size: 10px;
                    opacity: 0.7;
                    @include font-bold;
                    line-height: 1em;
                    transition: 0.4s;
                    @include break(mobile){
                        padding: 7px;
                        font-size: 10px;
                        bottom: 0;
                        transform: translateY(100%);
                    }
                &:hover {
                    opacity: 1;
                }
            }
        }

        .popup_module-04-slider {
            .slider_container {
                position: relative;

                .slider_nav {
                    @include absolute-center(center);
                    width: 95%;
                    z-index: 10;
                    display: flex;
                    justify-content: space-between;
                    pointer-events: none;
                    .slider_arrow {
                        pointer-events: all;
                        width: 30px;
                        opacity: 0.8;
                        &:hover {
                            opacity: 1;
                        }
                    }
                }

                .slide_caption {
                    padding: 8px;
                    font-size: 10px;
                    opacity: 0.7;
                    transition: 0.4s;
                    &:hover {
                        opacity: 1;
                    }
                }

                .img-container {
                    position: relative;
                }
                
                .slider_wrapper .slide picture.vertical img {
                    object-fit: contain;
                }
            }
        }

        .popup_module-05-testimonial {
            margin-left: -25px;
            margin-right: -25px;

            .testimony-content {
                width: 100%;
                padding-left: 25px;
                padding-right: 25px;
                position: relative;
                svg {
                    position: absolute;
                    width: 44px;
                    top: -20px;
                    left: 7px;
                    z-index: 0;
                }
                .txt {
                    position: relative;
                    z-index: 2;
                    @include font-medium;
                }
            }

            .testimonial_bottom {
                padding-top: 25px;
                padding-left: 25px;
                font-size: 1em;
                @include font-bold;
                width: calc(100% - 120px);
            }

            .testimony-img {
                position: absolute;
                bottom: -7px;
                right: 0;
            }
        }

        .popup_module-06-stories {
            .cards_highlight .highlight a {
                min-height: 450px;
                @include break(mobile) {
                    height: 100%;
                    min-height: 260px;
                }
            }

            .cards_highlight .highlight {
                padding-right: 0;
            }
        }
      }
}