.module-04-editorial_text {
    .top_container {
        text-align: center;
        padding-bottom: 12px;

        h2 {
            margin-bottom: 0;
        }
    }

    .multi-column {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include break(mobile) {
            flex-direction: column;
        }
    }

    &.layout-2col .col {
        width: 42%;

        @include break(mobile) {
            width: 100%;
        }
    }

    &.layout-3col .col {
        width: 30%;

        @include break(mobile) {
            width: 100%;
        }
    }

    .col {
        padding-top: 48px;
        text-decoration: none;

        .img-shape {
            width: 100%;
            padding-bottom: 100%;
            position: relative;
            margin-bottom: 32px;

            picture {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                .img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        p {
            color: $dark !important;
        }

        &:hover {
            .txt p {
                color: $dark;
            }
        }
    }

    .col_ghost {
        height: 0;
        padding: 0;
        margin: 0;
    }

}