.module-30-board {
    &:not(.bg_white) {
        padding-top: 0;
        padding-bottom: 0;
    }

    .top_container {
        padding-right: 15%;
        padding-bottom: 45px;

        @include break(tablet) {
            padding-right: 0;
        }

        .subtitle {
            font-size: 24px;
        }
    }

    .tab-name_container {
        overflow-x: scroll;
        overflow-y: visible;

        &::-webkit-scrollbar {
            width: 0 !important;
        }
        
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;

        .tab-name_container-inner {
            display: flex;
            position: relative;
            padding-bottom: 18px;
            margin-bottom: 30px;
            border-bottom: 2px solid rgba(52, 52, 54, 0.20);
            width: max-content;
            min-width: 100%;
        }

        .tab-name {
            font-size: 16px;
            @include font-bold;
            line-height: 140%;
            letter-spacing: 0.96px;
            text-transform: uppercase;
            margin-right: 32px;
            color: rgba($dark, 0.6);
            cursor: pointer;
            transition: all 0.4s;
            position: relative;
            z-index: 2;
            flex-shrink: 0;

            &::after {
                z-index: 2;
                content: " ";
                bottom: -20px;
                background-color: $pink;
                height: 2px;
                width: 100%;
                display: block;
                position: absolute;
                opacity: 0;
                transition: all 0.4s;
            }

            &:hover {
                color: $pink;
            }

            &.active {
                color: $pink;
                pointer-events: none;

                &::after {
                    opacity: 1;
                }
            }
        }
    }

    .tab_container {
        position: relative;

        .tab-list {
            &:not(:first-child) {
                display: none;
                opacity: 0;
            }
        }

        .tab-list_inner {
            column-count: 3;
            column-gap: 20px;

            .tab-donor {
                break-inside: avoid;
                margin-bottom: 15px;

                .title-donor {
                    font-size: 17px;
                }
            }
        }
    }

    .list-director {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: max-content;
        gap: 20px 26px;
        padding-bottom: 45px;

        @include break(tablet) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include break(mobile) {
            grid-template-columns: 1fr;
        }

        .directors {
            .name {
                @include font-bold;
                font-size: 20px;
                color: $pink;
            }

            .job {
                font-size: 17px;
            }
        }
    }

    .asterisk {
        font-size: 12px;
    }

    &.text {
        &_pink {

            .subtitle,
            .list-director .name,
            .asterisk span {
                color: $pink;
            }

        }

        &_green {

            .subtitle,
            .list-director .name,
            .asterisk span {
                color: $green;
            }
        }

        &_yellow {

            .subtitle,
            .list-director .name,
            .asterisk span {
                color: $yellow;
            }
        }

        &_blue {

            .subtitle,
            .list-director .name,
            .asterisk span {
                color: $blue;
            }
        }

        &_orange {

            .subtitle,
            .list-director .name,
            .asterisk span {
                color: $orange;
            }
        }

        &_light-pink {

            .subtitle,
            .list-director .name,
            .asterisk span {
                color: $light-pink;
            }

        }

        &_light-green {

            .subtitle,
            .list-director .name,
            .asterisk span {
                color: $light-green;
            }
        }

        &_light-yellow {

            .subtitle,
            .list-director .name,
            .asterisk span {
                color: $light-yellow;
            }
        }

        &_light-blue {

            .subtitle,
            .list-director .name,
            .asterisk span {
                color: $light-blue;
            }
        }

        &_light-orange {

            .subtitle,
            .list-director .name,
            .asterisk span {
                color: $light-orange;
            }
        }
    }
}