.module-bw-cpt-08_download-documents {

    .btn_wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;
        width: 100%;
        @include break(mobile){
            flex-direction: column;
            align-items: center;
        }

        .btn {
            margin-top: 0;
            margin-bottom: 20px;
            margin-left: 10px;
            margin-right: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 28px;

            &::after, &::before {
                display: none;
            }

            svg {
                path {
                    transition: all 0.4s;
                }
                margin-left: 14px;
            }
        }
    }

}