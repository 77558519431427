.module-27-conferences {
    
    .row_conf {
        display: flex;
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        border-top: 1px solid #AAA8AA;
        @include break(tablet){
            flex-direction: column;
            padding-top: 20px;
            padding-bottom: 20px;
        }

        &:last-of-type {
            border-bottom: 1px solid #AAA8AA;
        }

        .col_left {
            width: 360px;
            margin-right: 5%;
            flex-shrink: 0;
            @include break(tablet){
                width: 100%;
                margin-right: 0;
            }
        }

        .col_right {
            width: 100%;
            flex-shrink: 1;

            .tag_wrap {
                margin-top: 18px;
                display: flex;
                @include break(tablet){
                    margin-top: 10px;
                }
                .tag {
                    border-radius: 34px;
                    background: rgba(154, 10, 128, 0.08);
                    font-size: 10px;
                    @include font-medium;
                    letter-spacing: 0.6px;
                    text-transform: uppercase;
                    color: $pink;
                    padding: 4px 8px;
                    line-height: 1em;
                }
            }
        }

        .col_link {
            flex-shrink: 0;
            width: 40px;
            margin-left: 8%;
            @include break(tablet){
                margin-left: 0;
                margin-top: 10px;
            }
        }
    }

}