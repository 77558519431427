.module-02-editorial_image-text {
    overflow: hidden;

    &:not(.bg_white) {
        padding-top: 0px;
        padding-bottom: 0px;

        @include break(mobile) {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        .col_img-video {
            margin-top: 60px;
            margin-bottom: 60px;

            @include break(mobile) {
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }

        .col_content {
            padding-top: 120px;
            padding-bottom: 120px;

            @include break(mobile) {
                padding-top: 0px;
                padding-bottom: 0px;
            }
        }
    }

    // LAYOUT
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include break(mobile) {
            flex-direction: column;
        }
    }

    &.note-layout .container {
        width: 80%;
        max-width: 970px;
    }

    &.layout-txt-img .container {
        flex-direction: row-reverse;

        @include break(mobile) {
            flex-direction: column;

        }

        .col_content {
            padding-left: 0%;
        }

        .shape-bg {
            transform: translate(-50%, -50%);

            @include break(mobile) {
                transform: translate(0%, -50%) rotateY(180deg);
            }
        }
    }


    // RATIO
    &.ratio-half {
        .col_img-video {
            width: 45%;
            padding-bottom: 45%;
        }

        .col_content {
            width: 45%;
        }

    }

    &.ratio-left60 {
        .col_img-video {
            width: 35%;
            padding-bottom: 35%;
        }

        .col_content {
            width: 55%;
        }

        .shape-bg {
            left: 40%;
        }
    }

    &.layout-txt-img.ratio-left60 {
        .col_img-video {
            width: 55%;
            padding-bottom: 55%;
        }

        .col_content {
            width: 35%;
        }

        .shape-bg {
            left: 41%;
            width: 125%;
        }
    }

    &.ratio-right60 {
        .col_img-video {
            width: 55%;
            padding-bottom: 55%;
        }

        .col_content {
            width: 35%;
        }

        .shape-bg {
            left: 58%;
            width: 125%;
        }
    }

    &.layout-txt-img.ratio-right60 {
        .col_img-video {
            width: 35%;
            padding-bottom: 35%;
        }

        .col_content {
            width: 55%;
        }

        .shape-bg {
            left: 60%;
        }
    }

    &.ratio-left70 {
        .col_img-video {
            width: 30%;
            padding-bottom: 30%;
        }

        .col_content {
            width: 65%;
        }

        .shape-bg {
            left: 35%;
        }
    }

    &.layout-txt-img.ratio-left70 {
        .col_img-video {
            width: 65%;
            padding-bottom: 65%;
        }

        .col_content {
            width: 30%;
        }

        .shape-bg {
            left: 32%;
            width: 140%;
        }
    }

    &.ratio-right70 {
        .col_img-video {
            width: 65%;
            padding-bottom: 65%;
        }

        .col_content {
            width: 30%;
        }

        .shape-bg {
            left: 67%;
            width: 140%;
        }
    }

    &.layout-txt-img.ratio-right70 {
        .col_img-video {
            width: 30%;
            padding-bottom: 30%;
        }

        .col_content {
            width: 65%;
        }

        .shape-bg {
            left: 65%;
        }
    }



    // Image - Video
    .col_img-video {
        padding-bottom: 45%;
        position: relative;

        @include break(mobile) {
            width: 100% !important;
            padding-bottom: 100% !important;
        }

        .img-shape {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            .img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            video {
                @include absolute-center(center);
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .col_content {
        @include break(mobile) {
            margin-top: 40px;
            width: 100% !important;
        }
    }


    // BG SHAPE

    .shape-bg {
        pointer-events: none;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 110%;
        height: 100%;
        transform: translate(-50%, -50%) rotateY(180deg);

        @include break(mobile) {
            top: 17%;
            transform: translate(0%, -50%) rotateY(180deg);
        }
    }

}